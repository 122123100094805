

/* Importing MDC package */
@import "@material/layout-grid/mdc-layout-grid";
@import "./variables.scss";
@import "./fonts.scss";
@import './layout.css';
@import '@fontsource/noto-sans/400.css'; /* Regular */
@import '@fontsource/noto-sans/600.css'; /* Semibold */
@import '@fontsource/noto-sans/700.css'; /* Bold */
@import '@fontsource/noto-sans/800.css'; /* ExtraBold */
@import '@fontsource/noto-sans/600-italic.css'; /* Semibold Italic */
@import '@fontsource/noto-sans/700-italic.css'; /* Bold Italic */
@import '@fontsource/noto-sans/400-italic.css';


/* Rest style */
* {
    box-sizing: border-box;
}
body {
    padding: 0;
    margin: 0;
    font-family: 'Noto Sans',sans-serif;
    font-weight: normal;
}
img {
    max-width: 100%;
}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 700;
}
b, strong {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 700;
}
i {
    font-family: 'Noto Sans', sans-serif;
    font-style: italic;
}
input, button, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

/* Custom style */
.no-padding {
    padding: 0;
}
.mdc-layout-grid {
    footer & {
        height: inherit;
        padding: 11px .5em;
    }
    &.two-column-imgWrapper {
        padding: 32px;
    }
}
:root {
    --mdc-layout-grid-gutter-desktop: 32px;
    --mdc-layout-grid-gutter-tablet: 24px;
}

@media (min-width: 840px) and (max-width: 1023px) {
    :root {
        --mdc-layout-grid-gutter-desktop: 24px !important;
    }

    .interaction-custom-grid,
    .primary-panel {
        .mdc-layout-grid__inner {
            grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
            grid-column-end: span 8;
        }
    }
    .fullview-reference-panel {
        .mdc-layout-grid__inner, .reference-panel-header .mdc-layout-grid__inner {
            grid-template-columns: repeat(8, minmax(0, 1fr));
            grid-column-start: 2;
            grid-column-end: span 6 !important
        }
    }
}

.main-body {
    background-color: $wse_color_white;
    position: relative;
    z-index: 998;
    /* To restrict text selection inside the player */
    user-select: none;
    cursor: default;

    &.referencePanel-open {
        z-index: 1000;
    }
    > .mdc-layout-grid {
        height: inherit;
       > .mdc-layout-grid__inner {
            height: inherit;
            position: relative;
        }
    }
    .primary-panel {
        background-color: $wse_color_white;
        width: 100%;
    }
    .interaction-container {
        width: 100%;
        overflow: hidden;
    }
    .reference-panel {
        display: inline-block;
        width: 100%;
        overflow: auto;
        background-color: $wse_color_white;
      
        @media (min-width: 1024px) {
          position: absolute;
        }
      }
    .wse-custom-cell {
        position: relative;
        &__reference-screen {
            background-color: $wse_color_white;
            box-shadow: 0 1px 7px 0 $wse_color_light_grey_CC, 0 2px 0px 0 $wse_color_light_grey_CC;
            width: 0;
            position: absolute;
            right: 0;
            top: 0;
            grid-column-end: inherit;
            height: 100%;
            z-index: 11;
        }
    }
    /* Device = Laptops, Desktops */
    @media (min-width: 1024px) {
        .reference-screen-opened {
            .wse-custom-cell {
                &__interaction-screen {
                    grid-column-end: span 9;
                    width: 94%;
                }
                &__reference-screen {
                    width: 30%;
                }
            }
        }
        .view-mode {
            display: block;
        }
    }
    
    @media (min-width: 768px) and (max-width: 1023px) {
        .reference-screen-opened {
            .wse-custom-cell {
                &__reference-screen {
                    width: 100%;
                }
            }
        }
        .reference-panel-header {
            height: 104px;
            box-shadow: 0 15px 12px -15px rgba(0, 0, 0, 0.1);
            background-color: $wse_color_white;
        }
        .fullview-reference-panel {
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 1002;
            background-color: $wse_color_white;
            transition: 'all 0.4s ease-out';
        }
        /* Reference panel header height 104 + content area top margin 24 (104+28) */
        .reference-panel-content {
            margin: 24px 12px;
            height: calc(100vh - 128px);
            overflow-x: hidden;
            overflow-y: auto;
            @include hoverScrollbars;
        }
        .reference-panel-tablet-grid {
            width: 720px;
            margin: 0 auto;
            .reference-panel-content-area {
                p {
                    font-size: 1em;
                    margin: 0 4px 13px 4px;
                    line-height: 1.5;
                    i {
                        font-family: 'Noto Sans', sans-serif;
                        font-weight: 600;
                        font-style: italic;
                    }
                }
            }
        }
        .reference-panel-close-button {
            padding:16px 16px 0 0;
            min-height: 48px;
        }
        .empty-reference-panel {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: auto;
            flex-direction: column;
            height: calc(100% - 50px);
            color: $wse_color_darkest_grey;
            .noReferenceScreenIcon {
              margin-bottom: 16px;
            }
          }
    }


    /* Device = Most of the Smartphones Mobiles (Portrait) */
    @media (min-width: 320px) and (max-width: 480px) {
        .wse-custom-cell {
            &__interaction-screen {
                grid-column-end: span 12;
            }
        }
        .reference-screen-opened {
            .wse-custom-cell {
                &__reference-screen {
                    width: 286px;
                }
            }
        }
    }
}
header {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    background-color: $wse_color_white;
    width: 100%;
    padding: 5px 22px 5px 12px;
    border-bottom: 1px solid $wse_color_light_grey;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06);
    /* To restrict text selection inside the player */
    user-select: none;
    cursor: default;
    h1 {
        margin: 0.2em;
        font-size: 14px;
        text-align: center;
        font-weight: 600;
        font-family: 'Noto Sans';
    }
    .diableBackButton {
        pointer-events: none;
    }
}

@media (min-width: 1200px) {
    .mdc-layout-grid {
        footer & { 
            padding-top: 1.2em;
            padding-bottom: 1.2em;
        }
    } 
    header { 
        padding-top: 12px;
        padding-bottom: 12px;
        h1 { 
            font-size: 1em;
        }
    }
}

.overlay {
    position: absolute;
    background-color: rgba(0,0,0,.5);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
}
.interaction-custom-grid {
    padding: 20.8px 20px 8px 32px;
}

@media (min-width: 1024px) {
    .interaction-custom-grid .mdc-layout-grid__cell--span-10,
    .interaction-custom-grid .mdc-layout-grid__cell--span-10-desktop {
        grid-column-start: 2;
    }
}

.disable-scroll {
    overflow: hidden;
}

.imageContainer {
    position:relative;
    cursor: pointer;
    @include hoverImageContainer;
}

.imageColumnsContainer {
    display: block;
    position:relative;
    cursor: pointer;
    @include hoverImageContainer;
    width: 320px;
    height: 200px;
    div:first-of-type {
        position: relative;
        display: inline-block;
        width: auto;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.secondaryPanelContainer {
    .imageContainer {
        max-width: 240px;
        img {
            max-height: 300px;
        }
    }
}

@media (min-width: 768px) and (max-width: 1023px) { 
    .imageColumnsContainer {
        width: 255px;
        height: 140px;
    }
    .videoColumnsContainer, .audioColumnsContainer { 
        width: 255px !important;
    }
    .mdc-layout-grid {
        &.two-column-imgWrapper {
            padding: 24px;
        }
    }
}

.videoColumnsContainer, .audioColumnsContainer {
    display: block;
    width: 320px;
}

.audioColumnsContainer {
    height: 56px;
}

/* Stage color for the reference panel toggle button */
.survival {
    background-color: #fec3a4;

    &::before {
        border-bottom-color: #fec3a4;
    }
    &::after {
        border-top-color: #fec3a4;
    }
}

.waystage {
    background-color: #ff8d7e;

    &::before {
        border-bottom-color: #ff8d7e;
    }
    &::after {
        border-top-color: #ff8d7e;
    }
}

.upper_waystage {
    background-color: #f12b3e;

    &::before {
        border-bottom-color: #f12b3e;
    }
    &::after {
        border-top-color: #f12b3e;
    }
}

.threshold {
    background-color: $wse_color_cyan;

    &::before {
        border-bottom-color: $wse_color_cyan;
    }
    &::after {
        border-top-color: $wse_color_cyan;
    }
}

.milestone {
    background-color: #0182a9;

    &::before {
        border-bottom-color: #0182a9;
    }
    &::after {
        border-top-color: #0182a9;
    }
}

.mastery {
    background-color: $wse_color_blue;

    &::before {
        border-bottom-color: $wse_color_blue;
    }
    &::after {
        border-top-color: $wse_color_blue;
    }
}