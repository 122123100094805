@import 'video.js/dist/video-js.min.css';
@import '../../styles/variables.scss';

.player-videoFrame {
    max-width: 480px;
    .video-js-seek-hover {
        video::-webkit-media-controls-overlay-enclosure {
            background-color: rgba(0,0,0,0.6);
            cursor: pointer;
        }
    }
    .vjs-poster {
        background-size: cover;
    }
    .video-js.vjs-ended .vjs-poster {
        display: inline-block;
    }
    .video-js {
        font-size: 14px;
        max-width: 100%;
        max-height: 100%;

        .vjs-big-play-button {
            display: block;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            width: 59px;
            height: 59px;
            background-color: $wse_color_red;
            border-color: $wse_color_red;

            .vjs-icon-placeholder {
                &:before {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 30px;
                }
            }
        }

        &.vjs-has-started.vjs-paused,
        &.vjs-has-started.vjs-user-inactive {
            .vjs-big-play-button {
                display: none;
            }
        }
        &.vjs-has-started.vjs-playing,
        &.vjs-has-started.vjs-playing.vjs-user-inactive {
            .vjs-big-play-button {
                display: none;
            }
            .vjs-control-bar {
                display: flex;
            }
        }
    }
    .video-js .vjs-control:focus,
    .video-js .vjs-control:focus:before,
    .video-js .vjs-control:hover:before {
        text-shadow: none;
    }
    .vjs-text-track-display {
        bottom: 76px;
    }
    .vjs-control-bar {
        width: 100%;
        height: 32px;
        padding-bottom: 4px;
        justify-content: flex-start;
        background-color: rgba(0, 0, 0, 0.7);
        align-items: flex-end;
        .vjs-button.vjs-paused,
        .vjs-button.vjs-ended,
        .vjs-button.vjs-playing {
            height: 10px;
            width: 17px;
            margin-left: 12px;
            margin-right: 15px;
            font-size: 10px;
            margin-bottom: 13px;
        }
        .vjs-current-time,
        .vjs-duration {
            height: 10px;
            padding: 0 2px;
            display: inline-block;
            font-size: 10px;
            margin-bottom: 13px;
        }
        .vjs-time-control {
            min-width: auto;
        }
        .vjs-time-divider {
            display: block;
            height: 10px;
            padding: 0 2px;
            font-size: 10px;
            margin-bottom: 13px;
        }
        .vjs-current-time {
            display: block;
            font-size: 10px;
            margin-bottom: 13px;
        }
        .vjs-remaining-time {
            display: none;
        }
        .vjs-progress-control {
            position: absolute;
            top: 7px;
            height: auto;
            width: 100%;
            .vjs-progress-holder {
                margin: 0;
                height: 2px;
            }
            .vjs-play-progress {
                background-color: $wse_color_light_blue;
                padding-left: 9px;
                &:before {
                    display: none;
                }
                .vjs-time-tooltip {
                    display: none !important;
                    visibility: hidden !important;
                }
            }
            .vjs-load-progress {
                background-color: $wse_color_white;
            }
            .vjs-slider {
                background-color: rgba(239, 239, 239, 0.16);
            }
            .vjs-mouse-display {
                .vjs-time-tooltip {
                    background-color: transparent;
                    top: -2.4em;
                    font-size: 10px;
                }
            }
        }
        .vjs-time-divider {
            display: block;
        }
        .vjs-fullscreen-control {
            width: auto;
            margin-left: auto;
            height: 10px;
            margin-right: 30px;
            font-size: 10px;
            margin-bottom: 13px;
        }
    }
    .vjs-fullscreen {
        .vjs-control-bar {
            height: 80px;
            .vjs-button.vjs-paused,
            .vjs-button.vjs-ended,
            .vjs-button.vjs-playing {
                height: 23px;
                font-size: 14px;
                margin-bottom: 16px;
                margin-left: 32px;
                margin-right: 16.7px;
            }
            .vjs-current-time,
            .vjs-duration {
                height: 23px;
                font-size: 14px;
                margin-bottom: 16px;
            }
            .vjs-time-divider {
                height: 23px;
                font-size: 14px;
                margin-bottom: 16px;
            }
            .vjs-progress-control {
                top: 20px;
                .vjs-progress-holder {
                    height: 5px;
                }
                .vjs-mouse-display {
                    .vjs-time-tooltip {
                        font-size: 14px;
                    }
                }
            }
            .vjs-fullscreen-control {
                height: 27px;
                font-size: 14px;
                margin-bottom: 16px;
                margin-right: 50px;
            }
        }
    }
}
