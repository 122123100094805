@import '../../../styles/variables.scss';

.autoResizeTextArea {
  width: 100%;
  min-height: 40px;
  resize: none;
  border: solid 1px $wse_color_light_grey_CC;
  background-color: $wse_color_white;
  padding: 6px 16px;
  color: $wse_color_darkest_grey;
  line-height: 1.5;
  border: none;
  outline: none;
  border-bottom: 2px dotted $wse_color_dark_grey_70;
  overflow: hidden;
}
.autoResizeTextArea:focus {
  border-bottom: 2px solid $wse_color_blue;
}

.filled {
  border-bottom: solid 2px $wse_color_dark_grey_70;
}
