@import '../../../../styles/variables.scss';

.checkboxContainer {
    position: relative;
    display: inline-block;
    min-height: 40px;
    overflow: hidden;
    border-radius: 4px;
    width: 100%;

    input[type=radio] {
        margin: 0;
    }

    &.chosen {
        pointer-events: none;
        input {
            cursor: not-allowed;
            pointer-events: none;
            &:hover + .labelForCheck {
                border-color: $wse_color_light_blue_2;
            }
        }
        label{
            background-color: $wse_color_light_blue_2;
            border-color: $wse_color_light_blue_2;
        }
        &:disabled + .labelForCheck {
            background-color: $wse_color_light_blue_2;
            border-color: $wse_color_light_blue_2;
            color: inherit;
        }
    }

    &.disabled {
        pointer-events: none;
        input {
            cursor: not-allowed;
            &:hover  + .labelForCheck {
                border-color: $wse_color_light_grey_F7;
            }
        }
        label {
            background-color: $wse_color_light_grey_F7;
            border-color: $wse_color_light_grey_F7;
            color: $wse_color_dark_grey_70;
        }
    }

    .checkWithLabel {
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        &:hover + .labelForCheck {
            border-color: $wse_color_cyan_75;
            background-color: $wse_color_white;
        }
        &:checked {
            pointer-events: none;
        }
        &:checked + .labelForCheck {
            border-color: $wse_color_cyan_75;
            background-color: $wse_color_cyan_75;
            color: $wse_color_darkest_grey;
        }
        &:disabled + .labelForCheck {
            background-color: $wse_color_light_grey_F7;
            border-color: $wse_color_light_grey_F7;
            color: $wse_color_dark_grey_70;
        }
    }

    .labelForCheck {
        display: block;
        padding: 5px 15px;
        color: $wse_color_darkest_grey;
        border: 1px solid $wse_color_light_grey_CC;
        margin-bottom: 0;
        border-radius: inherit;
        font-weight: normal;
        font-size: 18px;
        line-height: 1.56;
        p {
            margin-bottom: 0;
            line-height: normal;
            font-size: 18px;
        }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        .labelForCheck {
            font-size: 16px;
            line-height: 1.5;
        }
    }

    &.isEnableToEdit {
        border-color: $wse_color_red;
        background-color: transparent;
        position: relative;
        .labelForCheck {
            border-radius: 4px;
            border-color: $wse_color_red;
            background-color: $wse_color_red_25;
            color: $wse_color_darkest_grey;
        }
        &:hover {
            &:before {
                background: none;
            }
            .labelForCheck {
                border-color: $wse_color_cyan_75;
                border-left: 1px solid $wse_color_cyan_75;
            }
        }
    }
}
