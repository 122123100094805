@import '../../../styles/variables.scss';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.activityCount {
    font-size: 14px;
    color: $wse_color_darkest_grey;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 2.632em;
    padding-left: .3em;
    b {
        font-family: $NotoSansBold;
        font-weight: 700;
        color: $wse_color_blue;
    }
    img {
        margin-left: 2px;
        width: 16px;
        height: 16px;
        opacity: .6;
    }
}
.open {
    img {
        transform: rotate(180deg);
    }
}
.active {
    border: 1px solid $wse_color_light_grey_F7;
    cursor: pointer;
    background-color: $wse_color_light_grey_F7;
    padding: 6px 8px;
    border-radius: 4px;
    min-width: 4em;
}

@media (min-width: 1200px) { 
    .activityCount {
        font-size: 1em;
    }
}
