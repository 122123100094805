.bookIcon {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-right: 36px;
    line-height: 0;
    svg {
        max-width: 100%;
        max-height: 100%;
    }
}