@import '../../styles/variables.scss';

.dsw-notification-bar {
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10500;
  overflow: hidden;
  transition: 0.5s ease-in-out all;

  .dsw-notification-left-icon {
    width: 60px;
    text-align: center;
    font-size: 1.6em;
    height: 60px;
    float: left;
  }

  .dsw-notification-center {
    height: 60px;
    float: left;
    width: calc(100% - (2 * 60px));
    color: $wse_color_darkest_grey;

    .dsw-notification__center__title {
      text-align: right;
      font-family: $NotoSansSemiBold;
      text-transform: none;
      font-size: 1.25em;
      line-height: 1.2em;
      padding: 0px 5px;
    }

    .dsw-notification__center__description {
      text-align: left;
      font-family: $NotoSansRegular;
      text-transform: none;
      font-size: 1em;
      line-height: 1.5em;
      padding: 0px 5px;
    }
  }

  .dsw-notification-left-icon i,
  .dsw-notification__center__title,
  .dsw-notification__center__description {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .dsw-notification__center__title,
  .dsw-notification__center__description {
    width: 50%;
    float: left;
    position: relative;
  }

  &.correct {
    color: $wse_color_green;
    background-color: $wse_color_green_20;
  }

  &.information {
    color: $wse_color_cyan;
    background-color: $wse_color_cyan_35;
  }

  &.attention {
    color: $wse_color_yellow;
    background-color: $wse_color_yellow_20;
  }

  &.incorrect {
    color: $wse_color_red;
    background-color: $wse_color_red_35;
  }

  @media only screen and (max-width: 768px) {
    .dsw-notification__center__title {
      width: 100%;
      text-align: center;
    }
    .dsw-notification__center__description {
      width: 100%;
      text-align: center;
      font-size: 12px;
      line-height: 1.3;
    }
  }

  @media (max-width: 992px) {
    .dsw-notification-left-icon {
      display: none;
    }
    .dsw-notification-center {
      width: calc(100% - (1 * 60px));
    }
  }
}