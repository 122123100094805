@import '../../styles/variables.scss';

.dsw-sub-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  .dsw-back-arrow {
    margin-right: 15px;
    cursor: pointer;
  }

  .dsw-mylibrary-icon {
    width: 60px;
    height: 60px;
    padding: 12px 3px 12px 4px;
  }

  .dsw-mylibrary-title {
    margin: 0;
    padding: 15px;
    font-family: $NotoSansSemiBold;
    color: $wse_color_darkest_grey;
    font-size: 28px;
  }
}

.dsw-img-front_cover {
  width: 268px;
  height: 366px;
}

.dsw-toc-container {
  padding: 0 !important;
  margin-top: 52px !important;
}