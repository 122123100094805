@import '../../styles/variables.scss';
.dsw-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $wse_color_darkest_grey;
  z-index: 1000;
  opacity: .5;
}

.dsw-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $wse_color_white;
  z-index: 1000;
  box-sizing: border-box;
  box-shadow: 0 0 5px $wse_color_darkest_grey;
  font-family: $NotoSansRegular;

  .dsw-modal-header {
    background-color: $wse_color_blue;
    color: $wse_color_white;
    padding: 27px;
    border: none;
    text-align: center;
    min-height: 16.43px;

    .dsw-modal-title {
      font-family: $NotoSansSemiBold;
      margin: 0;
      color: $wse_color_white;
      line-height: 1.428571429;
      text-transform: uppercase;
      font-size: 24px;
    }

    .close-modal {
      font-size: 1.9em;
      color: $wse_color_white;
      border: none;
      border-radius: 0;
      box-shadow: none;
      cursor: pointer;
      background: 0 0;
      position: absolute;
      right: 15px;
      top: 0px;

      &:focus {
        outline: none;
      }
    }
  }

  .dsw-modal-body {
    padding: 15px;
    position: relative;
  }

  .dsw-modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid $wse_color_light_grey_E5;
  }
}

@media (min-width: 768px) {
  .dsw-modal {
      width: 600px;
  }
}

@media (min-width: 992px) {
  .dsw-modal {
      width: 900px;
  }
}
