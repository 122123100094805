@import '../../styles/variables.scss';

.dsw-pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
  font-family: $NotoSansRegular;

  li {
    display: inline;
    text-align: -webkit-match-parent;

    a {
      border: 1px solid $wse_color_light_grey_DD;
      line-height: 23px;
      padding: 6px 12px;
      font-size: 12px;
      position: relative;
      float: left;
      text-decoration: none;
      background: 0 0;
      color: $wse_color_light_blue_42;
      background-color: $wse_color_white;
      margin-left: -1px;
      cursor: pointer;

      &:hover {
        color: $wse_color_light_blue_2A;
        background-color: $wse_color_light_grey_EE;
        border-color: $wse_color_light_grey_DD;
      }
    }

    &:first-child{
      a {
        margin-left: 0;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
      }
    }

    &:last-child {
      a {
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
      }
    }

    &.active {
      a {
        z-index: 2;
        color: $wse_color_white;
        background-color: $wse_color_light_blue_42;
        border-color: $wse_color_light_blue_42;
        cursor: default;
      }
    }

    &.disabled {
      a {
        color: $wse_color_dark_grey_77;
        background-color: $wse_color_white;
        border-color: $wse_color_light_grey_DD;
        cursor: not-allowed;

        &:active {
          pointer-events: none;
        }
      }
    }
  }
}