@import '../../styles/variables.scss';

.dsw-level-block {
  display: table-cell !important;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  .dsw-level-label {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    margin: 0;
    font-size: 20px;
    font-family: $NotoSansSemiBold;
    color: $wse_color_darkest_grey;
    cursor: pointer;
    line-height: 1.4;

    &.color-disable {
      color: $wse_color_dark_grey_70;
      cursor: auto;
    }
  }

  .dsw-level-percentage {
    font-size: 20px;
    padding-right: 10px;
    margin: 0;
    font-family: $NotoSansSemiBold;
    color: $wse_color_darkest_grey;
    line-height: 1.4;
  }

  .fa-check-circle {
    color: $wse_color_cyan;
    font-size: 28px;
    -webkit-text-stroke: 1.5px $wse_color_cyan;
  }

  .line-solid {
    padding-bottom: 25px;
    width: 487px;
    height: 1px;
    margin-top: 23px;
    border-top: 1px solid $wse_color_dark_grey_97;

    &.disable {
      border-color: $wse_color_white;
      padding-bottom: 0px;
    }
  }
}