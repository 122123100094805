@import "@material/layout-grid/mdc-layout-grid";
@import './styles/font-awesome.css';
@import './styles/variables.scss';
@import 'workbooks-player/dist/dsw.css';

html, body {
  margin: 0;
  background-color: $wse_color_light_grey;

  *,
  &:after,
  &:before {
    box-sizing: border-box;
  }

  .app-content {
    min-height: calc(100vh - 42px);
  }

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .app-content {
    padding: 115px 0px 40px 0px;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
  }

  @media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
  }
}