@import '../../styles/variables.scss';

.answer {
    padding: 7px 15px;
    border-radius: 4px;
    color: $wse_color_darkest_grey;
    background-color: $wse_color_green_20;
    display: inline-block;
    font-size: 18px;
    line-height: 1.34;
    min-height: 40px;
    vertical-align: middle;
    p {
        margin-bottom: 0;
    }
}
.answerWrapper {
    display: inline-block;
    margin-bottom: 8px;
    span {
        width: inherit;
    }
}
.correctAnswer {
    border: solid 1px $wse_color_green_20;
    background-color: $wse_color_green_20;
}

.wrongAnswerClose {
    padding-left: 7px;
    float: right;
}

.wrongAnswer {
    position: relative;
    overflow: hidden;
    border: solid 1px $wse_color_red;
    background-color: $wse_color_red_25;

    .dropdownArrow {
      margin-left: 12px;
      pointer-events: none;

      &:after {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $wse_color_darkest_grey;
        position: absolute;
        right: 10px;
        top: 13px;
        content: '';
    }
}
}
.newWrongAnswer {
    border: solid 1px $wse_color_red;
    background-color: $wse_color_red_25;
    cursor: pointer;
}
.minWidth {
    min-width: 170px;
}
.spacingTopRight {
    margin-top: 16px;
    margin-right: 16px;
    font-size: 18px;
}

.cursorPointer {
    cursor: pointer;
}

@media (min-width: 768px) and (max-width: 1199px) {
    .spacingTopRight, .answer {
        font-size: 16px;
        line-height: 1.5;
    }
  }