@import '../../../../styles/variables.scss';

.list {
  padding: 0;
  margin: 0;
}
.activityPaginationItem {
  border: 2px solid transparent;
  position: relative;
  list-style: none;
  display: inline-flex;
  border-radius: 50%;
  &:not(:last-child) {
    margin-right: 4px;
  }
  &:hover {
    .tooltipContainer {
      top: 33px;
      opacity: 1;
    }
  }
}
.tooltipContainer {
  position: absolute;
  left: calc(-80px /2.5);
  top: 33px;
  opacity: 0;
  background-color: $wse_color_dark_grey_50;
  font-size: 11px;
  padding: 4px 6px;
  width: 80px;
  border-radius: 1px;
  color: $wse_color_white;
  text-align: center;
  transition: all .3s ease-out;
  &::before {
    content: '';
    display: inline-block;
    top: -5px;
    left: calc(50% - 5px);
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $wse_color_dark_grey_50;
  }
}
.dot {
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: $wse_color_light_grey;
  cursor: pointer;
  margin: 2px;
}
.active {
  border-color: $wse_color_blue;
  .dot {
    pointer-events: none;
    cursor: default;
  }
}
.inActive {
  border: 2px solid transparent;
}
.completed {
  .dot {
      background-color: $wse_color_blue;
  }
}