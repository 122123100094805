@import '../../../styles/variables.scss';


.alignCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}
.interactionHeader {
    padding: 9px 28px;
    justify-content: flex-start;
    background-color: $wse_color_white;
    color: inherit;
    border-left: 12px solid transparent;
    align-items: center;
    transition: all .5s ease;
    border-bottom: 1px solid rgba(166, 184, 197, 0.65);
    width: 100%;
    min-height: 72px;
    &.alignTop {
        align-items: flex-start;
    }
}
.title {
    padding: 0;
    font-size: 1em;
    line-height: 1.500em;
    min-width: 7em;
    font-family: $NotoSansRegular;
    color: $wse_color_darkest_grey;
    font-weight: normal;
    margin: 3px 0 0;
    justify-content: flex-start;
    b {
        font-family: $NotoSansBold;
        font-weight: 700;
        font-size: 1.375em;
        margin-left: 10px;
    }
}
.primary {
    min-height: 70px;
}
.secondary {
    margin-left: 38px;
    font-size: 16px;
    align-self: center;
    p {
        margin: 0;
    }
}
.toggleContent {
    display: inline-block;
    color: $wse_color_light_blue;
    font-family: $NotoSansSemiBold;
    font-weight:600;
    position: relative;
    cursor: pointer;
    min-width: 5.3em;
    padding-right: 16px;
    &:after {
        content: '';
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        right: 0;
        top: calc(50% - 2px);
    }
    &.downArrow {
        &:after {
            border-top: 5px solid  $wse_color_light_blue;
        }
    }
    &.topArrow {
        &:after {
            top: calc(50% - 4px);
            border-bottom: 5px solid  $wse_color_light_blue;
        }
    }
}
.content {
    align-items: baseline;
    flex-direction: column;
    overflow: hidden;
    justify-content: flex-start;
    line-height: 1.5;
    &.open {
        height: auto;
    }
    &.close {
        height: 48px;
    }
}
.activityTitle {
    font-family: $NotoSansSemiBold;
    font-weight: 600;
    margin: 0;
     p {
         font-family: inherit;
     }
}
.noInstructionAlignCenter {
    align-items: center;
}

@media (min-width: 1200px) { 
    .interactionHeader {
        padding-top: 24px;
        padding-bottom: 24px;
        min-height: 120px;
    }
    .title {
        font-size: 1.250em;
        b {
            font-size: 1.750em;   
        }
    }
    .secondary { 
        font-size: 1em;
    }
}