@import '../../../styles/variables.scss';

.container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: $wse_color_white;
    .header {
        justify-content: space-between;
        align-items: center;
        padding: 0.8em;
        min-height: 50px;
        .title {
            font-size: 1.250em;
            margin: 0;
            color: $wse_color_blue;
            font-family: $NotoSansSemiBold;
            font-weight:600;
            text-transform: capitalize;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .arrow {
                display: inline-block;
                margin-right: 0.6em;
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-right: 6px solid $wse_color_blue;
            }
        }
        .audioContainer {
            display: inline-flex;
        }
    }
}

@media (min-width: 1024px) {
    .container {
        .header {
            display: flex;
            .title {
                display: inline-flex;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .tabletMainHeader {
        width: max-content;
        display: flex;
    }
    .audioContainer {
        margin-left: 16px;
    }
    .container {
        margin: 0px 0.75em;
        .header {
          padding: 0.875em 0;
        }
      }
}