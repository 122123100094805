@import '../../styles/variables.scss';

.wordReference {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 1.25em;
    background-color: $wse_color_cyan_light_1;
    border-top: 1px solid $wse_color_cyan_light_1;
    overflow: auto;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    white-space: pre;
    &.partialContainer {
        left: 50%;
    }
    @media screen and (max-width: 839px) {
        padding: 0.4em;
    }
}
.wordBank{
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    li {
        &:last-child {
            .fillingWord {
                border-right: none;
            }
        }
    }

}
.fillingWord {
    display: inline-flex;
    border-right: 1px solid $wse_color_light_grey_CC;
    font-size: 1em;
    padding: 4px 15px;
    cursor: default;
    align-items: center;
    &.used {
        background-color: transparent;
        color: $wse_color_grey;
        event-pointer: none;
    }
}
.count {
    display: inline-block;
    background-color: $wse_color_cyan_35;
    color: $wse_color_darkest_grey;
    border-radius: 2px;
    padding: 0 6px;
    margin-left: 8px;
    font-size: 14px;
    width: 20px;
    height: 20px;
}

@media (min-width: 1200px) { 
    .fillingWord { 
        font-size: 18px;
    }
}