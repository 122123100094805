@import '../../../styles/variables.scss';

.interaction-container {
    position: relative;
    &.splitView {
        display: flex;
        width: 100%;
        .word-reference + .activity-body {
            padding: 0;
        }
        .activity-body {
            padding-left: .4em;
        }
        .auxiliaryMedia {
            .audioContainer {
                margin: 0px auto 16px;
            }
            .player-videoFrame {
                margin: 0 auto;
            }
        }
    }

    // Conversation layout interactions style
    .conversationContainer {
        display: flex;

        .indentationContainer {
            padding-left: 32px;
            display: block;
            width: 100%;
        }
    }
}
