@import '../../styles/variables.scss';

.circleBackground,
.circleBackgroundDisable,
.circleProgress {
  fill: none;
}
.circleContainer {
  align-self: center;
  .circleBackground{
    stroke: $wse_color_light_grey_CC;
  }

  .circleProgress {
    stroke: $wse_color_cyan;
    stroke-linecap: square;
    stroke-linejoin: round;
  }

  .circleText {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.29;
    font-family: $NotoSansSemiBold;
    color: $wse_color_darkest_grey;
    &.circleTextComplete {
      font-size: 23px;
    }
  }

  .circleBackgroundDisable {
    stroke: $wse_color_light_grey_CC;
  }
}

