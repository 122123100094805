@import '../../styles/variables.scss';

.ordering.mdc-layout-grid__cell--span-10-desktop {
  grid-column-start: 2;
}

.ordering-grid {
  grid-row-gap: unset !important;
}

.ordering {
  grid-row-gap: 16px !important;

  .items {
    cursor: pointer;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    border: solid 1px transparent;
    background-color: $wse_color_cyan_35;
    font-size: 1.125em;
    padding: 12px 16px 12px 4px;
    cursor: pointer;
    min-height: 48px;
    opacity: 1;
    position: relative;
    transform: translate(0, 0);

    &.hide {
      opacity: 0;
      transition: all .2s ease-out;
    }

    .dotContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
      width: 8px;
      height: 16px;
      margin-left: 4px;
      flex: none;

      .dotPattern {
        background-image: radial-gradient(ellipse 100% 100%, transparent, transparent 40%, transparent 40%), radial-gradient(ellipse 60% 60%, $wse_color_light_blue, $wse_color_light_blue 40%, transparent 60%);
        background-size: 0px 0px, 5px 6px;
        flex: 1 1 auto;
        background-position: -1px -1px;
      }
    }

    &.anchor {
      background-color: rgba(166, 211, 225, 0.3);
      pointer-events: none;
    }

    &.completed {
      pointer-events: none;
    }

    &.hovering {
      background-color: transparent;
      border: solid 1px $wse_color_cyan_75;
    }

    &:active, &.preview {
      background-color: $wse_color_cyan_75;
    }

    &.correct {
      background-color: $wse_color_green_20;
      cursor: default;
      pointer-events: none;
    }

    &.wrong {
      background-color: $wse_color_red_25;
      border: solid 1px $wse_color_red;

      .dotPattern {
        background-image: radial-gradient(ellipse 100% 100%, transparent, transparent 40%, transparent 40%), radial-gradient(ellipse 60% 60%, $wse_color_red, $wse_color_red 40%, transparent 60%);
      }
    }

    &.solution {
      border: solid 2px $wse_color_cyan_75;
      background-color: $wse_color_light_blue_25;
      cursor: default;
    }

    p {
      height: 100%;
      margin: 0;
      padding-left: 12px;
      color: $wse_color_darkest_grey;
      font-size: 18px;
      line-height: 1.34;
    }
  }

  .items:last-child { 
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .ordering {
    .items {
      p {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }
}