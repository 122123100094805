@import "../../styles/variables.scss";

.dropDownContainer {
  display: flex;
  margin-bottom: 18px;
  justify-content: flex-end;

  .dropDown {
    position: relative;

    .dropDownToggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 172px;
      height: 40px;
      margin-top: 12px;
      margin-right: 17px;
      padding: 8px 11px 8px 17.1px;
      border-radius: 2px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
      background-color: $wse_color_white;
      border: none;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.36;
      color: $wse_color_darkest_grey;
      text-align: left;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      span {
        padding-left: 1.7px;
        padding-right: 2.7px;
        font-size: 24px;
        font-weight: 700;
      }
    }

    .dropDownMenu {
      min-width: 172px;
      margin-top: 3px;
      padding: 9px 7px 15px 7px;
      box-shadow: 0 8px 4px 0 rgba(0, 0, 0, 0.22), 0 11px 11px 0 rgba(0, 0, 0, 0.22);
      background-color: $wse_color_white;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      float: left;
      margin: 2px 0 0;
      list-style: none;
      font-size: 14px;
      text-align: left;
      background-clip: padding-box;

      .dropDownItem {
        a {
          display: block;
          padding: 3px 0px;
          clear: both;
          font-weight: 400;
          line-height: 1.428571429;
          color: $wse_color_dark_grey;
          white-space: nowrap;
          cursor: pointer;

          span {
            min-width: 12px;
            margin-left: 6px;
            display: inline-block;
            font-size: 12px;
          }
        }
      }
    }

    &.open {
      .dropDownMenu {
        display: block;
      }
    }
  }
}
