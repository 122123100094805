
@import '../../styles/variables.scss';

.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: -5px 1px 4px 3px $wse_color_light_grey_E9;
    padding: 0 1em;
    background-color: $wse_color_light_grey_F7;
    z-index: 10;
}
.centerAlign {
    text-align: center;
}

@media (max-width: 780px) {
    .footer {
        padding: 0 1em;
    }
}

.confirmButton {
    background-color: $wse_color_blue !important;
}

.confirmButton:focus {
    background-color: $wse_color_blue !important;
    box-shadow: 0 0 0 3px $wse_color_blue !important;
}

.popUpButton {
    display: flex !important;
    flex-direction: row-reverse;
}