@import '../../styles/variables.scss';

.corner-loader {
  position: fixed;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 200px 200px 0;
  border-color: transparent rgba(255, 255, 255, 0.94) transparent transparent;
  right: 0;
  top: 0;
  transform: translateX(100px) translateY(-100px);
  z-index: 11000;
  transition: transform 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  pointer-events: none;

  &.corner-loader-animate {
    transform: translateX(0px) translateY(0px);

    .circle-loader {
      transform: translateZ(0);
      animation: load8 1.1s infinite linear;
    }
  }

  .circle-loader {
    margin: 60px auto;
    font-size: 10px;
    position: absolute;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(64, 102, 131, 0.3);
    border-right: 1.1em solid rgba(64, 102, 131, 0.3);
    border-bottom: 1.1em solid rgba(64, 102, 131, 0.3);
    border-left: 1.1em solid $wse_color_blue_75;
    left: 92px;
    top: -51px;

    @media only screen and (max-width: 992px) {
      left: 91px;
      top: -45px;
    }

    @media only screen and (max-width: 768px) {
      left: 47px;
      top: -55px;
    }
  }

  .circle-loader,
  .circle-loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;

    @media only screen and (max-width: 992px) {
      width: 8em;
      height: 8em;
    }

    @media only screen and (max-width: 768px) {
      width: 5em;
      height: 5em;
    }

  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @media only screen and (max-width: 992px) {
    border-width: 0 180px 180px 0;
  }

  @media only screen and (max-width: 768px) {
    border-width: 0 100px 100px 0;
  }
}