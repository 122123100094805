@use "@material/theme" with (
    $primary: #003359,
    $secondary: #76ff03,
    $background: #FFF,
);

@use "@material/button";
@include button.core-styles;

/* Importing Sass variables */
@import '../../styles/variables.scss';

.wse-custom-mdc-btn {
  min-width: 140px;
  min-height: 40px;
  letter-spacing: 0;
  font-size: 1em;
  padding: 0 24px;
  margin: 0 0.5em;
  box-shadow: none;
  font-family: $NotoSansSemiBold;
  font-weight: 600;

  &:hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  }

  &.disabled {
    background-color: var(--mdc-theme-primary, $wse_color_blue);
    color: var(--mdc-theme-background, $wse_color_white);
    opacity: 0.6;
  }

  &.disableClick {
    pointer-events: none;
  }

  &.solution {
    background-color: var(--mdc-theme-primary, $wse_color_white);
    color: var(--mdc-theme-background, $wse_color_blue);
    font-size: 1em;
    font-weight: 600;
    text-transform: capitalize;
    min-width: 129px;
    padding-left: 14px;
    padding-right: 20px;
    min-height: 42px;
    line-height: 1.5;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 2px 2px 0 rgb(0 0 0 / 24%);

    .wse-mdc-icon {
      &.left {
        margin-right: 12px;
      }
    }
  }

  &.next {
    font-weight: 600;
    text-transform: capitalize;
    min-width: 106px;
    padding-left: 20px;
    padding-right: 16px;
    min-height: 42px;
    line-height: 1.5;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.24);

    .wse-mdc-icon {
      &.right {
        margin-left: 12px;
      }
    }
  }

  &.backToActivity {
    text-transform: none;
    padding: 0 16px 0 20px;
    font-weight: 600;
    font-size: 16px;
    font-family: $NotoSansSemiBold;
    min-height: 42px;

    &.confirm, &.reject {
      padding: 0px;
      min-width: 65px;
    }

    &.backToWorkBook {
      background-color: var(--mdc-theme-primary, $wse_color_white);
      color: var(--mdc-theme-background, $wse_color_blue);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    }

    &.continueCourse {
      padding: 0 20px 0 16px;
    }
  }

  &.continue {
    text-transform: capitalize;
    min-width: 98px;
    height: 48px;
    font-family: $NotoSansSemiBold;
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    @media (min-width: 768px) and (max-width: 1023px) {
      margin : 0;
    }
  }

  .wse-mdc-icon {
    &.left {
      margin-right: 5px;
    }

    &.right {
      margin-left: 5px;
    }
  }

  .check-answer-count {
    display: inline-block;
    padding: 2px 8px;
    background-color: $wse_color_white;
    border-radius: 4px;
    min-width: 26px;
    min-height: 26px;
    margin-left: 12px;
    color: $wse_color_red;
    font-family: $NotoSansBold;
    font-weight: 700;
  }

  &:first-child {
    margin-left: 0;
  }

  @media (max-width: 1024px) {
    min-width: 110px;
  }
}

.custom-mdc-icon {
  min-width: 50px;

  &.print {
    min-width: 24px;
  }
}

.wse-custom-mdc-btn.check-answer {
  padding: 9px 16px 9px 20px;
  text-transform: capitalize;
  min-height: 42px;
  font-family: $NotoSansSemiBold;
  font-weight: 600;
  font-size: 1em;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.24);

  &.disabled {
    opacity: 0.5;
    box-shadow: none;
  }

  &:hover {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.18), 0 4px 3px 0 rgba(0, 0, 0, 0.23);
  }

  &:active {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.18), 0 4px 3px 0 rgba(0, 0, 0, 0.23);
    background-color: var(--mdc-theme-primary, #4c708b);
    opacity: 1;
  }
}

.view-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 5px;
  opacity: .5;

  &.portrait {
    flex-direction: column;
    min-width: 25px;

    span {
      height: 5px;
      width: 15px;

      &:first-child {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        margin-bottom: 2px;
      }

      &:last-child {
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
  }

  &.splitView {
    flex-direction: row;
    min-width: 20px;

    span {
      height: 13px;
      width: 5px;

      &:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        margin-right: 2px;
      }

      &:last-child {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
  }

  &.active {
    opacity: 1;
    cursor: default;
    pointer-events: none;
  }

  span {
    display: inline-block;
    width: 100%;
    background-color: $wse_color_blue;
  }
}

.mdc-button {
  &.wse-mdc-icon-btn {
    width: 40px;
    min-width: auto;
    height: 40px;
    background-color: var(--mdc-theme-background, $wse_color_light_grey_F7);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 0px 1px 0px rgba(0, 0, 0, 0.12);

    &:hover {
      box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }

    img {
      width: 24px;
      height: 24px;
      max-width: inherit;
    }
  }

  &.print {
    margin-right: 6px;
  }
}

.print-inactive, .erase-inactive {
  opacity: 0.2;
  cursor: default;
  pointer-events: none;
  line-height: 0;
}

.print-active, .erase-active {
  opacity: 1;
  cursor: pointer;

  &.print-click-disable {
    cursor: default;
    pointer-events: none;
  }

  .content {
    display: inline-flex;
  }
}

.animate {
  animation: bounce 0.75s ease 0s 2;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-8px);
  }
}

.wse-custom-mdc-btn.backToActivity {
  .wse-mdc-icon.left {
    margin-right: 16px;
  }

  .wse-mdc-icon.right {
    margin-left: 16px;
  }
}

@media (max-width: 480px) {
  .wse-custom-mdc-btn.check-answer {
    height: 42px;
    min-width: 84px;
    padding: 8px 12px 8px 12px;
    margin: 0;
  }

  .wse-custom-mdc-btn.solution {
    min-width: 48px;
    padding: 14px 16px;

    .wse-mdc-icon {
      margin: 0;
    }
  }

  .wse-custom-mdc-btn.next {
    min-width: 48px;
    padding: 14px 16px;
    margin-right: 0;

    .wse-mdc-icon {
      margin: 0;
    }
  }

  .wse-custom-mdc-btn.backToActivity {
    min-width: 48px;
    padding: 14px 16px;

    .wse-mdc-icon.left {
      margin-right: 16px;
    }
  }

  .check-answer .content,
  .solution .content,
  .next .content {
    display: none;
  }

  .wse-custom-mdc-btn.check-answer .check-answer-count,
  .wse-custom-mdc-btn.solution .wse-mdc-icon.left,
  .wse-custom-mdc-btn.next .wse-mdc-icon.right {
    margin: 0;
  }
}