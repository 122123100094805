@import '../../../../styles/variables.scss';

.referenceCategoryTitle {
    justify-content: space-between;
    align-items: center;
    color: $wse_color_blue;
    font-family: $NotoSansSemiBold;
    font-weight:600;
    text-transform: capitalize;
    &.normalView {
        margin: 13px 23px 13px 24px;
        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }
    &.expandedView {
        margin: 0 0 .5em 0;
        img {
            width: 25px;
            height: 25px;
            cursor: pointer;
            transform: rotate(45deg);
            transition: all .2s ease-out;
        }
    }
}

@media (min-width: 1024px) {
    .referenceCategoryTitle {
        font-size: 1.250em;
        display: flex;
    }
}

@media (min-width: 768px) and (max-width: 1023px) { 
    .referenceCategoryTitle {
        font-size: 16px;
        &.normalView {
            padding: 16px 15px 15px 15px;
            margin: unset;
            display: inline-block;
        }
    }
    .activeTab{
        border-bottom: 2px solid $wse_color_red;
        margin-bottom: 15px;
    }
}