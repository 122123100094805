@import '../../styles/variables.scss';

.eolInProgressContainer {
  display: flex;
  flex-direction: column;
  .circularProgressContainer {
    display: flex;
    margin-bottom: 88px;
    .circularProgressList {
      margin-left: 79px;
      flex-direction: column;
      display: flex;
      &:first-child {
        margin-left: 0px;
      }
    }
    .circularProgressTitle {
      font-family: $NotoSansRegular;
      font-size: 18px;
      line-height: 1.67;
      color: $wse_color_dark_grey_50;
      align-self: center;
      padding-top: 8px;
    }
  }

  .circularProgress {
    align-self: center;
  }
  .celebrationIconContainer {
    align-self: center;
    margin-bottom: 6px;
  }

  .iconListContainer {
    align-self: center;
    margin-bottom: 88px;
    .showIcon {
      border-radius: 50%;
      padding: 12px 9px 11px 10px;
      display: inline-flex;
      margin-left: 20px;
      &:first-child {
        margin-left: 0px;
      }
    }
    .completed {
      background: $wse_color_blue;
      pointer-events: none;
    }
    .inProgress {
      background: $wse_color_light_grey;
      cursor: pointer;
      img {
        padding: 1px;
     }
    }
  }

  .todoTextContainer {
    margin-top: 16px;
    margin-bottom: 40px;
    align-self: center;
    .todoText {
      font-size: 44px;
      line-height: 1.27;
      font-family: $NotoSansRegular;
      color: $wse_color_darkest_grey;
    }
  }
  .buttonContainer {
    align-self: center;
  }

  @media (max-height : 750px){
    .celebrationIconContainer {
      width: 150px;
      height:150px;
    }
    .circularProgressContainer {
      margin-bottom: 52px;
    }
  }
}


