.questionContainer {
    .question {
        margin-bottom: 2.188em;
        text-align: left;

        > p {
            margin-bottom: 24px;
        }

        .list {
            list-style: none;
            padding: 0;
            margin: 0;

            .listItem {
                padding: 0;
                line-height: 0.625em;
                > span {
                    font-size: 18px;
                    margin: 0;
                }
                p {
                    line-height: normal;
                    font-size: 18px;
                }
            }

            .fullContainer {
                width: 100%;
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
                > span {
                    width: inherit;
                }
            }
        }
    }

    &:last-child {
        .question {
            margin-bottom: 0;
        }
    }
}
