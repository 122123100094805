@import '../../../../styles/variables.scss';

.wordReference {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    padding: 0 32px;
    background-color: $wse_color_white;
    overflow: auto;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    border-bottom: 1px solid $wse_color_light_grey_CC;
    overflow: hidden;
    padding-bottom: 6px;

    .wordBank{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }
}

.wrongAnswerContainer {
    pointer-events:none;
}

.wordContainer {
    margin: 7px 16px 0 0;
}
.hide {
    transition: all .2s ease-out;
    opacity: 0;
}

.droppedWord {
    height: 40px !important;
}

.fillingWord {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: $wse_color_cyan_35;
    border: solid 1px transparent;
    font-size: 1em;
    padding: 8px 16px 8px 8px;
    cursor: pointer;
    height: 35px;
    opacity: 1;
    position: relative;
    &:hover:not(.exampleAnswer) {
        background-color: $wse_color_white;
        border: solid 1px $wse_color_cyan_75;
        &:active {
            background-color: $wse_color_cyan_75;
            border-color: $wse_color_cyan_75;
        }
        .count {
            border: solid 1px $wse_color_cyan_75;
        }
        &.used {
            .dotPattern {
            background-image: radial-gradient(ellipse 100% 100%, transparent, transparent 40%, transparent 40%), radial-gradient(ellipse 60% 60%, $wse_color_light_blue, $wse_color_light_blue 40%, transparent 60%);
            }
        }
    }

    .dotContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        width: 10px;
        height: 16px;
        margin-right: 12px;
        .dotPattern {
            background-image: radial-gradient(ellipse 100% 100%, transparent, transparent 40%, transparent 40%), radial-gradient(ellipse 60% 60%, $wse_color_light_blue, $wse_color_light_blue 40%, transparent 60%);
            background-size: 0px 0px, 6px 6px;
            flex: 1 1 auto;
            background-position: -1px -1px;
        }
    }
    &.used {
        background-color: $wse_color_light_grey;
        color: $wse_color_darkest_grey;
        event-pointer: none;
        .dotPattern {
            background-image: radial-gradient(ellipse 100% 100%, transparent, transparent 40%, transparent 40%), radial-gradient(ellipse 60% 60%, $wse_color_grey, $wse_color_grey 40%, transparent 60%);
        }
    }
    &.exampleAnswer {
        padding-left: 16px;
        cursor: default;
    }
    &.usedCategory {
        display: none;
    }
    &.isWrongAnswer {
        background-color: $wse_color_red_25;
        color: $wse_color_darkest_grey;
        border: 1px solid $wse_color_red;
        cursor: pointer;
        .dotPattern {
            background-image: radial-gradient(ellipse 100% 100%, transparent, transparent 40%, transparent 40%), radial-gradient(ellipse 60% 60%, $wse_color_red, $wse_color_red 40%, transparent 60%);
        }
    }
    .count {
        background-color: $wse_color_white;
        color: $wse_color_red;
        border-radius: 50%;
        font-size: 16px;
        line-height: 1.5;
        width: 21px;
        height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 7px;
        &.countHide {
            opacity: 0;
        }
    }
}

@media (min-width: 1200px) {
    .wordReference {
        padding-bottom: 15px;
    }
    .wordContainer {
        margin-top: 16px;
    }
    .fillingWord { 
        font-size: 1.125em;
        height: 40px;
    }
}