@media (min-width: 1024px) {
  .interaction-custom-grid {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .reference-screen-opened .interaction-custom-grid,
  .splitView .interaction-custom-grid {
    width: 100%;
    padding-left: 32px !important;
    padding-right: 20px !important;
    margin: 0 auto !important;
  }

}

@media (min-width: 1024px) and (max-width: 1042px) {
  .interaction-custom-grid {
    margin-left: 32px !important;
    margin-right: 20px !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .primary-panel .mdc-layout-grid__cell--span-6-tablet,
  .interaction-custom-grid .mdc-layout-grid__cell--span-6,
  .interaction-custom-grid .mdc-layout-grid__cell--span-6-tablet,
  .word-definition .mdc-layout-grid__cell--span-6-tablet,
  .reference-panel, .reference-panel-content-area {
    grid-column-start: 2;
    grid-column-end: span 6 !important;
  }

  .secondaryPanelContainer.mdc-layout-grid__inner {
    width: 720px;
    margin: 0 auto;
  }

  .interaction-custom-grid {
    width: 720px;
    padding: 20.8px 0px 8px 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 786px) {
  .interaction-custom-grid {
    margin-left: 24px !important;
    margin-right: 12px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .interaction-custom-grid {
    width: 960px;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .interaction-custom-grid {
    width: 1140px;
  }
}

@media (min-width: 1600px) {
  .interaction-custom-grid {
    width: 1320px;
  }
}