@import '../../../../styles/variables.scss';

.dsw-review-block {
  margin-left: -10px;

  .dsw-review {
    cursor: pointer;
    display: flex;
    justify-content: center;

    label {
      font-size: 16px;
      font-family: $NotoSansRegular;
      color: $wse_color_darkest_grey;
      cursor: pointer;
    }
  }
}

