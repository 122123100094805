@import '../../../styles/variables.scss';

.dsw-level-rectangle {
  height: 86px;
  background-color: $wse_color_white;
  display: flex;

  .level-rectangle-strap {
    width: 10px;
    height: 86px;
    background-color: $wse_color_skin;
  }

  .dsw-level-title {
    padding-left: 10px;
    font-size: 28px;
    font-family: $NotoSansSemiBold;
    color: $wse_color_darkest_grey;
  }

  .dsw-btn-resume {
    min-width: 50px;
    height: 42px;
    padding: 9px 17px 9px 16px;
    border-radius: 4px;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.14), 0 1px 4px -1px rgba(0, 0, 0, 0.24);
    background-color: $wse_color_red;
    color: $wse_color_white;
    margin: 20px;
    cursor: pointer;
    font-size: 16px;
    font-family: $NotoSansSemiBold;
    line-height: 1.5;
  }

  .div-circular-bar {
    padding: 12px 0px;

    text {
      font-size: 16px;
      font-family: $NotoSansRegular;
      color: $wse_color_darkest_grey;
    }
  }
}