@import '../../../../styles/variables.scss';

.indentContent {
    padding: 3.5em 2.5em 1.5em;
    position: relative;
    p {
        img {
            min-width: 50px;
            min-height: 50px;
            max-width: 240px;
            max-height: 300px;
            margin: 10px 0;
        }
    }
}
.foldingEffect {
    position: absolute;
    line-height: 0;
    width: 40px;
    height: 40px;
    right: 0;
    top: 0;
    &:before {
        content:'';
        border-bottom: 40px solid $wse_color_peach_35;
        border-right: 40px solid transparent;
        position: absolute;
        left: 0px;
        top: 0;
    }
    &:after {
        content:"";
        position: absolute;
        border-top: 47px solid #fff7f6;
        border-left: 47px solid transparent;
        right: -3px;
        top: -3px;
    }
}