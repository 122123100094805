@import '../../styles/variables.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  transform: translateZ(0);
  background-color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
   /* To restrict text selection inside the player */
   user-select: none;

  &.eolModal {
    background-color: $wse_color_white;
  }

  &.confirmPopup,
  &.eolModal {
    cursor: default;
  }

  .modalClose {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 32px;
    height: 32px;
    padding: 6px;
    line-height: 1;
    background: transparent;
    border: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .modalContainer {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    background: transparent;
    cursor: pointer;

    &.eolModal,
    &.confirmPopup {
      cursor: default;
    }

    .eol {
      background-color: $wse_color_white;
      border-radius: 12px;
      border: solid 1px $wse_color_light_grey_CC;
    }

    .modalContent {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        max-width: 100%;
        max-height: 100%;
        cursor: default;
      }
    }
  }
}
