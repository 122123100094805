@import '../../../styles/variables.scss';

.dsw-units {
  background-color: $wse_color_white;
  padding: 30px;

  .dsw-units-rectangle {
    background-color: $wse_color_white;
    border: 1px solid $wse_color_light_grey_CC;
    border-radius: 6px;

    .dsw-unit-container {
      padding: 0px 50px;

      .dsw-unit-title {
        font-size: 26px;
        font-family: $NotoSansSemiBold;
        color: $wse_color_darkest_grey;
        text-align: center;
        margin: 0;
        padding-top: 5px;
      }

      .dsw-unit-progress {
        text-align: center;
      }
    }
  }

  .circle-text {
    font-size: 16px;
    font-family: $NotoSansRegular;
    color: $wse_color_darkest_grey;
    font-weight: inherit;
  }
}

.margin-top-bottom-35 {
  margin: 35px 0px;
}
