@import '../../../../styles/variables.scss';

.dsw-credits-table {
  background-color: $wse_color_white;
  opacity: 1;
  position: relative;
  transition: opacity .5s ease;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 14px;

  thead {
    tr {
      th {
        display: table-cell;
        vertical-align: bottom;
        border-bottom: 2px solid $wse_color_light_grey_DD;
        padding: 8px;
        line-height: 1.428571429;
        text-align: left;
      }
    }
    &:first-child>tr:first-child>th {
      border-top: 0;
    }
  }

  tbody {
    cursor: pointer;

    tr {
      th,td {
        padding: 8px;
        line-height: 1.428571429;
        vertical-align: top;
        border-top: 1px solid $wse_color_light_grey_DD;

        .dsw-file-pdf-icon {
          padding-right: 5px;
        }
      }

      a {
        float: right;
        text-decoration: none;
        color: $wse_color_dark_grey;
      }

      &:hover {
        background-color: $wse_color_light_grey_F5;
      }
    }
  }
}