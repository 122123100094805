@import '../../styles/variables.scss';

.imageContainer {
    box-shadow: 0 2px 12px -5px rgba(0, 0, 0, 0.5);
    background-color: $wse_color_white;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    max-width: 100%;
    max-height: 100%;
    position:relative;
    cursor: pointer;
    @include hoverImageContainer;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}
.mediaContainer {
    margin-bottom: 16px;
}

.orientationImageContainer {
  &.portraitContainer {
    max-width: 240px !important;
  }
  &.landscapeContainer {
    max-width: 480px;
  }
  max-height: 300px;
}

.outerImageContainer {
  width: 100%;
  height: 0;
  position: relative;
  &.landscape {
    padding-top: 56.25%;
  }
  &.portrait {
    padding-top: 125%;
  }
  .innerImageContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}

.answerContainer {
  margin-top: 24px;
}
