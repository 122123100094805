@import '../../../styles/variables.scss';

.container {
    flex: 0 0 50%;
    img {
        max-width: 100%;
        max-height: 100%;
    }
    .header {
        padding: 0.875em 2em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        background-color: $wse_color_peach_light_2;
        font-family: $NotoSansSemiBold;
        font-weight:600;
        color: $wse_color_darkest_grey;
        .title {
            font-size: 1.125em;
        }
        .toggleArrow {
            margin-left: auto;
            display: inline-block;
            width: 1.5em;
            height: 1.5em;
        }
        @media (min-width: 768px) and (max-width: 1023px) {
            padding-left: 1.5em;
            padding-right: 1.5em;
        }
    }
}
.secondaryPanelOpen {
    height: 100%;
    .content {
        padding-right: .75em;
        padding-bottom: 0;
        height: calc(100% - 3.685em);
        max-height: 100%;
    }
    .whiteContainer {
        margin: 5px;
    }
}
.content {
    padding: 0 2em 2em 2em;
    background-color: $wse_color_peach_light_2;
    height: calc(100% - 52px);
}
.indentContent {
    padding: 2.5em 2.5em 1.5em;
}
.scrollContainer {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    @include hoverScrollbars;
}
.whiteContainer {
    background-color: $wse_color_white;
    box-shadow: 0 0 4px 0 $wse_color_peach_35;
    position: relative;
    p {
        font-size: 1em;
        margin: 0 0 1em 0;
        color: $wse_color_darkest_grey;
        line-height: 1.5;
    }
}
.scrollContainer {
    padding-right: .7em;
    height: calc(100% - 1.875em);
    overflow: auto;
    @include hoverScrollbars;
}
.splitView {
    .content {
        padding: 1.765em 0.75em;
        height: 100%;
        overflow: hidden;
    }
    .scrollContainer {
        padding: 0.25em 0.75em 0.25em 1.25em;
        margin: 0;
        height: 100%;
    }
}
