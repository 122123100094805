@import '../../../styles/variables.scss';

.toggleButton {
  background-color: $wse_color_white;
  cursor: pointer;
  border: none;
  position: absolute;
  left: -20px;
  top: 12px;
  border-right-color: transparent;
  outline: none;
  padding: 0;
  min-height: 46px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
  &.disabled {
      pointer-events: none;
  }

  &:before {
      content:'';
      display: inline-block;
      width: 0;
      height: 0;
      border-bottom: 7px solid $wse_color_white;
      border-left: 20px solid transparent;
      position: absolute;
      top: -7px;
      left: 0;
  }
  &:after {
      content:'';
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 7px solid $wse_color_white;
      border-left: 20px solid transparent;
      position: absolute;
      bottom: -7px;
      left: 0;
  }
  .content {
      line-height: 0;
  }
  img {
      width: 22px;
      height: 22px;
  }
}

@media (min-width: 1200px) { 
    .toggleButton { 
        top: 30px;
        min-height: 64px;
    }
}
