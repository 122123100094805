@import './variables.scss';

/**
* 400 is the same as normal/regular
* 600 semi bold
* 700 is the same as bold
* 900 extra bold
*/

.font-normal {
  font-family: $NotoSansRegular;
  font-weight:400;
  font-style: normal;
}

.font-normal-italic {
  font-family: $NotoSansRegularItalic;
  font-weight: 400;
  font-style: italic;
}

.font-semi-bold {
  font-family: $NotoSansSemiBold;
  font-weight: 600;
  font-style: normal;
}

.font-semi-bold-italic {
  font-family: $NotoSansSemiBoldItalic;
  font-weight: 600;
  font-style: italic;
}

.font-bold {
  font-family: $NotoSansBold;
  font-weight: 700;
  font-style: normal;
}

.font-bold-italic {
  font-family: $NotoSansBoldItalic;
  font-weight: 700;
  font-style: italic;
}
