@import 'react-multi-carousel/lib/styles.css';
@import '../../../styles/variables.scss';

.dsw-carousel-container {
  height: 450px;
  margin-bottom: 30px;
  padding-left: 15px;

  .react-multi-carousel-track {
    margin-top: -50px;
  }

  .dsw-carousel-dot {
    padding-top: 100px;
  }
}

.react-multi-carousel-dot--active button {
  background: $wse_color_blue_75 !important;
  border-color: $wse_color_blue_75 !important;
}

.react-multi-carousel-dot {
  margin-right: 10px;
}

.react-multi-carousel-dot button {
  border-color: $wse_color_blue_35;
  background: $wse_color_blue_35;
}

.dsw-carousel-custom-group {
  top: 45%;
  position: absolute;
  width: 100%;

  .react-multiple-carousel__arrow {
    background: $wse_color_light_grey_F7;
    min-width: initial;
    min-height: initial;
    width: 40px;
    height: 40px;
    z-index: 999;
    margin-top: -25px;
    padding: 8px;

    &::before {
      display: none;
    }

    &:hover {
      background: $wse_color_blue_35;
    }
  }

  .react-multiple-carousel__arrow--right {
    right: calc(0% - 60px);
  }

  .react-multiple-carousel__arrow--left {
    left: calc(0% - 60px);
  }
}
