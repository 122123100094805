@import '../../../../styles/variables.scss';

.container {
    background-color: $wse_color_cyan_35;
    color: $wse_color_darkest_grey;
    border: 1px solid $wse_color_cyan_35;
    display: inline-block;
    vertical-align: middle;
    min-height: 40px;
    border-radius: 4px;
    padding: 8px 16px;
    line-height: normal;
    cursor: pointer;
}

.emptyBordered {
    border: 1px dashed $wse_color_light_blue;
    background-color: $wse_color_white;
    position: absolute;
    border-radius: 4px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
    &.show {
        opacity: 1;
    }
}