@import "./fonts.scss";
@import "./variables.scss";
@import '@fontsource/noto-sans/400.css';
@import '@fontsource/noto-sans/600.css';
@import '@fontsource/noto-sans/700.css';
@import '@fontsource/noto-sans/800.css';
@import '@fontsource/noto-sans/600-italic.css';
@import '@fontsource/noto-sans/700-italic.css'; 
@import '@fontsource/noto-sans/400-italic.css';

.no-padding {
  padding: 0px !important;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-15 {
  padding: 15px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-25 {
  margin: 25px;
}

.padding-left-15 {
  padding-left: 15px;
}

.padding-left-10 {
  padding-left: 10px;
}

.margin-left-50 {
  margin-left: 50px;
}

.margin-top-18 {
  margin-top: 18px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.text-right {
  text-align: right;
}

.d-flex {
  display: flex!important;
}

.d-block {
  display: block!important;
}

.d-none {
  display: none!important;
}

.no-margin {
  margin: 0;
}