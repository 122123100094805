@import '../../styles/variables.scss';

.fill {
    padding: 8px 16px;
    border-radius: 4px;
    color: $wse_color_darkest_grey;
    background-color: $wse_color_light_blue_2;
    display: inline-block;
    line-height: 1.34;
    margin-bottom: 8px;
}
.filled {
    @extend .fill;
    background-color: $wse_color_light_grey_F7;
    border-radius: 0;
}

.fullWidth {
    width: 100%;
}

.spacingTopRight {
    margin-top: 16px;
    margin-right: 16px;
    height: 40px;
    font-size: 18px;
    line-height: 1.33;
    padding: 16px;
    margin-bottom: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 768px) and (max-width: 1199px) {
    .spacingTopRight {
        font-size: 16px;
        line-height: 1.5;
    }
  }