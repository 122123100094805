@import '../../../../styles/variables.scss';

.userAnswer {
  width: 100%;
  min-height: 40px;
  padding: 5px 15px;
  border-radius: 4px;
  background-color: $wse_color_white;
  line-height: 1.56;
  color: $wse_color_darkest_grey;
  margin-bottom: 20px;
  display: block;
  word-break: break-word;
  border: solid 1px $wse_color_light_grey_CC;

  span {
    font-family: inherit;
    margin: 0;
    white-space: pre-wrap;
  }
}