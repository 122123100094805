// Font family
$NotoSansRegular: 'Noto Sans', sans-serif;
$NotoSansRegularItalic: 'Noto Sans', sans-serif;
$NotoSansSemiBold: 'Noto Sans', sans-serif;
$NotoSansSemiBoldItalic: 'Noto Sans', sans-serif;
$NotoSansBold: 'Noto Sans', sans-serif;
$NotoSansBoldItalic: 'Noto Sans', sans-serif;


// Primary Colors
$wse_color_blue: #003359;
$wse_color_blue_75: #406683;
$wse_color_blue_35: #A6B8C5;
$wse_color_red: #F12C3E;
$wse_color_red_75: #F5616E;
$wse_color_red_35: #FAB5BC;
$wse_color_red_25: #FEEDEF;
$wse_color_cyan: #64CFE9;
$wse_color_cyan_75: #8BDBEF;
$wse_color_cyan_35: #C9EEF7;
$wse_color_cyan_35_light_4:rgba(201, 238, 247, 0.4);
$wse_color_cyan_light_1: rgba(166, 184, 197, 0.1);

// Secondary colors
$wse_color_peach: #FF8E7E;
$wse_color_peach_75: #FFAA9E;
$wse_color_peach_35: #FFD8D2;
$wse_color_peach_light_2: rgba(255, 216, 210, 0.2);
$wse_color_light_blue: #0082A9;
$wse_color_light_blue_75: #40A1BF;
$wse_color_light_blue_35: #A6D3E1;
$wse_color_light_blue_2: #EEF7F9;
$wse_color_skin: #FFC3A4;
$wse_color_skin_75: #FFD2BB;
$wse_color_skin_35: #FFEADF;
$wse_color_light_blue_25: #F2FBFD;

// Tertiary colors
$wse_color_yellow: #FFDD15;
$wse_color_yellow_60: #FFEB73;
$wse_color_yellow_20: #FFF5B8;
$wse_color_green: #6DBD46;
$wse_color_green_60: #A7D790;
$wse_color_green_20: #EDF7E7;

// Full spectrum
$wse_color_orange: #F69022;
$wse_color_orange_60: #F9BD81;
$wse_color_orange_20: #FDE3CB;
$wse_color_lime: #C8DA35;
$wse_color_lime_60: #DEE98A;
$wse_color_lime_20: #F1F6CE;
$wse_color_purple: #713E97;
$wse_color_purple_60: #AA8CC0;
$wse_color_purple_20: #DBCFE5;
$wse_color_jade: #01A14B;
$wse_color_jade_60: #71C794;
$wse_color_jade_20: #D0ECDC;

// Grey
$wse_color_darkest_grey: #1A1A1A;
$wse_color_dark_grey: #333333;
$wse_color_dark_grey_50: #5A5A5A;
$wse_color_dark_grey_70: #707070;
$wse_color_grey: #999999;
$wse_color_light_grey_AA: #AAAAAA;
$wse_color_light_grey_CC: #CCCCCC;
$wse_color_light_grey: #EFEFEF;
$wse_color_light_grey_E9: #E9E9E9E9;
$wse_color_light_grey_F7: #F7F7F7;
$wse_color_white: #FFFFFF;

@mixin scrollbars($width, $height) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width:  $width;
    height: $height;
    scroll-behavior: smooth !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $wse_color_grey;
    border-radius: 5.5px !important;
  }
  &::-webkit-scrollbar-thumb:vertical {
      height: $height;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    background-clip: content-box;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $wse_color_dark_grey_70;
    scrollbar-track-color: $wse_color_white;
  }
}
@mixin scrollbarsTransparent($width, $height) {
  @include scrollbars($width, $height);
  // For Google Chrome
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  // For Internet Explorer
  & {
    scrollbar-face-color: transparent;
    scrollbar-track-color: transparent;
  }
}

@mixin hoverScrollbars {
  @include scrollbarsTransparent(6px, 104px);
  &:hover {
      @include scrollbars(6px, 104px);
  }
}

@mixin hoverImageContainer {
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #1a1a1a;;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    opacity: 0;
  }
  &:hover {
      span {
          opacity: 0.65;
          img {
              margin: 16px 17px 17px 16px;
          }
      }
  }
}