@import '../../styles/variables.scss';

.tooltip
{
  position: absolute;
  left: -76px;
  bottom: calc(100% + 10px);
  font-size: 12px;
  width: 168px;
  max-height: 143.6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px $wse_color_light_grey;
  border-radius: 1px;
  background-color: $wse_color_white;
  cursor: default;
}

.tooltip .tooltip-arrow
{
  position: absolute;
  bottom: 0;
  left: calc(50% - 8px);
}
.tooltip .tooltip-arrow:after,
.tooltip .tooltip-arrow:before {
  content: "";
  position: absolute;
}
.tooltip .tooltip-arrow:after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid $wse_color_white;
  bottom: -9px;
  left: 0;
}
.tooltip .tooltip-arrow:before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid $wse_color_light_grey;
  bottom: -9px;
  left: 0;
}
.tooltip .tooltip-content
{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 115px;
  padding: 15px;
  font-size: 18px;
  line-height: 1.67;
  color: $wse_color_darkest_grey;
  overflow: auto;
}