.gapContainer {
  display: inline;
  vertical-align: middle;
  position: relative;
  span {
    margin-bottom: 0;
  }
}

.gapContainerDrop {
  margin: 16px 16px 0 0;
}
.disabled {
  cursor: not-allowed;
  pointer-events: none;
}