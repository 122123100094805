.audioVersion {
  position: relative;
  padding-right: 5px;
  font-size: 10px;
  margin-right: 7px;
  cursor: pointer;
  &.active {
    pointer-events: none;
  }
  img {
      width: 24px;
      height: 24px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-right: unset;
    margin-right: 24px;
  }
}

