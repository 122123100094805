@import '../../../../styles/variables.scss';

.notebook {
    position: relative;
    padding-top: 37px;
    background-color: $wse-color-white;
    &::before {
        content: '';
        width: 2px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 40px;
        background-color: rgba(255, 142, 126, 0.65);
    }
}

.notebookLine {
    height: 100%;
    background-image: repeating-linear-gradient($wse-color-white 0px, $wse-color-white 23px, rgba(255, 142, 126, 0.3) 24px);
}

.content {
    font-family: $NotoSansRegularItalic;
    padding-top: 30px;
    padding-left: 58px;
    line-height: 1.5;
    padding-right: 40px;
    p {
        font-family: inherit;
        &:first-child:last-child {
            margin-bottom: 0;
        }
    }
}