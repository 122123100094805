.circle-background,
.circle-background-disable,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #c9eef8;
}

.circle-progress {
  stroke: #64cfe9;
  stroke-linecap: square;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 14px;
  font-weight: bold;
}

.circle-background-disable {
  stroke: #cccccc;
}