@import '../../styles/variables.scss';

.textArea {
  width: 100%;
  height: 363px;
  resize: none;
  border-radius: 4px;
  border: solid 1px $wse_color_light_grey_CC;
  background-color: $wse_color_white;
  padding: 15px;
  font-size: 1.125em;
  color: $wse_color_darkest_grey;
  line-height: 1.56;
  overflow-x: hidden;
  overflow-y: auto;
  @include hoverScrollbars;

  &.filled {
    border: solid 1px $wse_color_dark_grey_70;
  }

  &:focus {
    outline: none;
    border: solid 1px $wse_color_blue;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .textArea {
        font-size: 1em;
        line-height: 1.5;
  }
}