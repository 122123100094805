@import '../../../../styles/variables.scss';

.definitionContainer {
  margin: .4em .75em;
  height: calc(100% - (50px + 0.8em));
  overflow-y: auto;
  @include hoverScrollbars;
  .detailedContainer {
    .detailedContainer__Title {
      font-family: $NotoSansBold;
      font-weight: 700;
      color: $wse_color_darkest_grey;
      font-size: 1em;
      margin: 0.4em 0;
      text-transform: capitalize;
    }
    p {
        margin: 0 0 1em;
        font-size: 1em;
        line-height: 1.5;
        i {
            font-family: $NotoSansRegularItalic;
        }
    }
  }
  .emptyContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    flex-direction: column;
    height: calc(100% - 50px);
    color: $wse_color_dark_grey_50;
    font-size: 1em;
    .noRecordIcon {
      width: 45px;
      height: 45px;
      margin-bottom: 7px;
    }
  }
}

@media (min-width: 1024px) {
  .detailedContainer {
    padding: 0 0.6em;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .definitionContainer {
    margin: unset;
    > div{
      padding-top: 18px;
    }
  }
}