@import '../../styles/variables.scss';

.selectWrapper {
  display: inline-block;
  vertical-align: middle;
  min-height: 38px;
  position: relative;
  top: 2px;

  .selectBox {
    display: inline-flex;
    position: relative;
    height: 32px;
    overflow: hidden;
    min-width: 160px;
    border-bottom: 2px solid $wse_color_dark_grey_70;

    .selectDropdownArrow {
      right: 8px;
      position: absolute;
      top: -4px;
      pointer-events: none;

      &:after {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $wse_color_darkest_grey;
        position: absolute;
        right: 10px;
        top: 15px;
        content: '';
      }
    }

    .selectedValue {
      font-family: $NotoSansRegular;
      line-height: 1.56;
      width: 100%;
      height: 100%;
      padding: 2px 30px 0 16px;
      white-space: nowrap;
      cursor: pointer;
      border-radius: 4px;
    }

    &.active {
      border-bottom: 2px solid $wse_color_blue;
      .selectDropdownArrow:after {
        top: 10px;
        transform: rotate(180deg) translateY(-5px);
      }
    }
    &.wrong {
      border-bottom: 2px solid $wse_color_red;
    }
  }

  .selectDropdown {
    display: none;
    position: absolute;
    transform: scale(1);
    opacity: 0;
    z-index: 1;
    transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    box-shadow: 0 12px 12px 0 rgba(26, 26, 26, 0.16);
    background-color: $wse_color_white;
    top: 34px;
    left: 0px;
    min-width: 160px;
    &.opened {
      display: inline-block;
      transform: scale(1);
      opacity: 1;
    }

    .selectDropdownList {
      .selectDropdownListItem {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 8px 16px;
        overflow: hidden;
        min-height: 44px;
        cursor: pointer;
        &:active {
          background-color: $wse_color_light_grey_F7;
        }
        &.selected {
          font-family: $NotoSansSemiBold;
          font-weight: 600;
        }
      }
    }
  }
}
