@import '../../styles/variables.scss';

.audioContainer {
  padding: 8px 16px;
  border-radius: 6px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  background-color: $wse_color_white;
  min-height: 56px;
  max-width: 320px;
  margin-bottom: 16px;

  .video-js {
    background: none;
    padding: 0;

    .vjs-loading-spinner, .vjs-load-progress {
      display: none;
    }

    .vjs-hidden {
      display: none !important;
    }

    .vjs-control-bar {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      width: 100%;
      height: 40px;
      position: relative;
      background: none;

      .vjs-time-control {
        font-size: 13px;
        color: $wse_color_darkest_grey;
        min-width: 40px;
        padding: 0;
      }

      .vjs-progress-holder {
        height: 4px;
        border-radius: 3.5px;
        background: $wse_color_light_grey_CC;
      }

      .vjs-mouse-display, .vjs-time-tooltip {
        display: none;
      }

      .vjs-remaining-time {
        span:nth-child(2) {
          display: none;
        }
      }
    }

    .vjs-play-progress:before {
      display: none;
    }

    .vjs-big-play-button {
      display: none;
    }

    .vjs-play-progress {
      background-color: $wse_color_light_blue;
      border-radius: 3.5px;
      width: 100%;
    }

    .vjs-play-control .vjs-icon-placeholder:before {
      content: "";
      background-image: url('../../assets/images/svg/audio-play-icon.svg');
      background-repeat: no-repeat;
    }

    .vjs-play-control.vjs-playing .vjs-icon-placeholder:before,
    .vjs-icon-pause:before {
      content: "";
      background-image: url('../../assets/images/svg/audio-pause-icon.svg');
      background-repeat: no-repeat;
    }

    .vjs-fullscreen-control {
      display: none;
    }
  }

  .video-js.vjs-has-started {
    .vjs-progress-holder {
       background: $wse_color_light_blue_35;
    }
  }

  &:hover {
    background-color: $wse_color_light_grey_F7;
  }
}
