@import '../../../../styles/variables.scss';

.dsw-book-wrapper {
  width: 263px;
  height: 361px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;

  .dsw-book-tile {
    background-size: 233px;
    background-repeat: no-repeat;
    width: 233px;
    height: 320px;

    .dsw-book-progress {
      float: right;
      margin-top: 30px;
      margin-right: 10px;
      display: flex;

      label {
        color: $wse_color_darkest_grey;
        font-size: 14px;
        font-family: $NotoSansSemiBold;
        margin: 4px 10px 0px 0px;
        line-height: 1.43;
      }

      .fa-check-circle {
        color: $wse_color_cyan;
        font-size: 28px;
        -webkit-text-stroke: 1.5px $wse_color_cyan;
      }
    }

    &.current-tile {
      background-size: 263px;
      width: 263px;
      height: 361px;
    }
  }
}



