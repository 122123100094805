@import '../../../styles/variables.scss';

.questionContainer {
    padding-top: .7em;
    p {
        font-size: 1.125em;
        margin: 0 0 16px;
        line-height: 1.56;
    }
}
.auxiliaryMediaContainer {
    margin-bottom: .5em;
    padding-top: .7em;
    text-align: center;
}
.imageContainer {
    max-height: 480px;
    max-width: 480px;
    position: relative;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    @include hoverImageContainer;
}
.splitView {
    flex: 0 0 50%;
    border-left: 1px solid $wse_color_light_grey;
    .auxiliaryMedia, .interaction {
        grid-column: 1 / -1;
    }
}

@media (min-width: 1024px) {
    .multiMediaContainer > div {
        margin: 0 auto;
    }
}

@media (min-width: 768px) {
    .multiMediaContainer > div {
        margin: 0 auto;
    }
}

.tabletLandscapeView {
    .auxiliaryMedia, .interaction {
        grid-column: 1 / -1;
    }
}

.activityContainer {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: relative;
}
.activityBody {
    height: calc(100% - .75em);
    margin: 0 0.75em 0.25em 0;
    overflow-x: hidden;
    overflow-y: auto;
    @include hoverScrollbars;
}

@media (min-width: 1200px) {
    .activityBody {
        margin-top: 0.4em;
    }
}

@media (min-width: 768px) and (max-width: 1199px) { 
    .questionContainer { 
        p {
            font-size: 1em;
            line-height: 1.5;
        }
    }
}