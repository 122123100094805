@import '../../styles/variables.scss';

.solution {
    padding: 6px 14px;
    border-radius: 4px;
    color: $wse_color_darkest_grey;
    background-color: $wse_color_cyan_35_light_4;
    display: inline-flex;
    line-height: 1.34;
    border: 2px solid $wse_color_cyan_75;
    margin-bottom: 8px;
    p {
        margin-bottom: 0;
    }
}

.multiSolution {
    width: 20px;
    height: 20px;
    padding: 4px;
    background-color: $wse_color_blue;
    border-radius: 50%;
    margin: 2px 0 2px 8px;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    pointer-events: all;
}

.fullWidth {
    width: 100%;
}

.spacingTopRight {
    margin-top: 16px;
    margin-right: 16px;
    font-size: 18px;
}

@media (min-width: 768px) and (max-width: 1199px) {
    .spacingTopRight {
        font-size: 16px;
        line-height: 1.5;
    }
  }