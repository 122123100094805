@import './variables.scss';

/**
* 400 is the same as normal/regular
* 600 semi bold
* 700 is the same as bold
* 900 extra bold
*/

@font-face {
  font-family: 'FontAwesome';
  src: url('../assets/fonts/fontawesome-webfont.eot');
  src: url('../assets/fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/fontawesome-webfont.woff') format('woff'), url('../assets/fonts/fontawesome-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: "Pyidaungsu";
  src: url('../assets/fonts/Pyidaungsu/Pyidaungsu-Regular.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Pyidaungsu/Pyidaungsu-Regular.eot?#iefix') format("embedded-opentype"), /* IE6-IE8 */
    url('../assets/fonts/Pyidaungsu/Pyidaungsu-Regular.otf') format("opentype"), /* Open Type Font */
    url('../assets/fonts/Pyidaungsu/Pyidaungsu-Regular.svg') format("svg"), /* Legacy iOS */
    url('../assets/fonts/Pyidaungsu/Pyidaungsu-Regular.ttf') format("truetype"), /* Safari, Android, iOS */
    url('../assets/fonts/Pyidaungsu/Pyidaungsu-Regular.woff') format("woff"), /* Modern Browsers */
    url('../assets/fonts/Pyidaungsu/Pyidaungsu-Regular.woff2') format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

.font-normal {
  font-family: $NotoSansRegular;
  font-weight: 400;
  font-style: normal;
}

.font-normal-italic {
  font-family: $NotoSansRegularItalic;
  font-weight: 400;
  font-style: italic;
}

.font-semi-bold {
  font-family: $NotoSansSemiBold;
  font-weight: 600;
  font-style: normal;
}

.font-semi-bold-italic {
  font-family: $NotoSansBoldItalic;
  font-weight: 600;
  font-style: italic;
}

.font-bold {
  font-family: $NotoSansBold;
  font-weight: 700;
  font-style: normal;
}

.font-bold-italic {
  font-family: $NotoSansBoldItalic;
  font-weight: 700;
  font-style: italic;
}
