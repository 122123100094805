@import '../../../styles/variables.scss';

.dsw-book-description {
  font-size: 12px;
  color: $wse_color_blue_35;
  margin-top: 40px;
  font-family: $NotoSansRegular;

  p {
    margin-top: 0;
    line-height: 1.5;
  }

  .dsw-copy-rights {
    line-height: 2.5;
    margin-bottom: 5px;

    span {
      display: block;
      line-height: 1.5;
      margin-top: 7px;
      margin-bottom: 7px;
    }
  }
}