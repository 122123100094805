@import '../../../../styles/variables.scss';

.dsw-lesson-container{
  .dsw-lesson-block {

    &.pointer {
      cursor: pointer;
    }

    .fa-arrow-right {
      -webkit-text-stroke: 1px white;
      color: $wse_color_light_grey_CC;

      &.active{
        color: $wse_color_cyan;
      }
    }

    .dsw-lesson-label {
      font-size: 20px;
      font-family: $NotoSansRegular;
      color: $wse_color_darkest_grey;
      cursor: inherit;
      display: inline-block;
      margin-bottom: .5rem;
      line-height: 1.5;
      white-space: nowrap;
    }

    .dsw-lesson-icon {
      width: 18px;
      cursor: inherit;
      vertical-align: middle;
      margin-left: 10px;
      margin-top: 5px;
    }
  }

  .mdc-layout-grid__cell {
    margin-bottom: -25px;

    &:nth-child(3n) {
      margin-bottom: 0px;
    }
  }

  .padding-top-5 {
    padding-top: 5px;
  }

  .padding-top-7 {
    padding-top: 7px;
  }
}

.margin-bottom-_27 {
  margin-bottom: -27px;
}

.padding-left-5 {
  padding-left: 5px;
}
