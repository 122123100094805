@import '../../../../styles/variables.scss';

.container {
    background-color: $wse_color_white;
    border: 1px solid $wse_color_light_grey_CC;
    display: inline-block;
    vertical-align: middle;
    min-width: 160px;
    height: 40px;
    border-radius: 4px;
    padding: 8px 16px;
    line-height: normal;
}
.entered {
   border-style: dashed;
   border-color: $wse_color_light_blue;
}