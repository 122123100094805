@import '../../../styles/variables.scss';

.dsw-footer-credits {
  .dsw-modal-body {
    overflow: auto;
    min-height: 400px;
    max-height: calc(70vh - (200px));
    overflow-y: scroll;
    overflow-x: hidden;
    overflow-wrap: break-word;

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    .dsw-credits-accordion {
      margin-left: 10px;
      margin-bottom: 20px;
      font-family: inherit;
    }

    .dsw-credits-pagination {
      margin-left: 10px;
    }
  }

  .dsw-modal-footer {
    .close-btn {
      color: $wse_color_white;
      background-color: $wse_color_blue;
      border-color: $wse_color_blue_02;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      margin-bottom: 0;
      font-weight: 700;
      border: 1px solid transparent;
      padding: 10px 20px;
      font-size: 14px;
      line-height: 1.428571429;
      border-radius: 0;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.14), 0 2px 5px rgba(0, 0, 0, 0.24);
      transition: box-shadow .2s cubic-bezier(0.4, 0, 0.2, 1),
      border .2s cubic-bezier(0.4, 0, 0.2, 1),
      background-color .2s cubic-bezier(0.4, 0, 0.2, 1);
      cursor: pointer;
    }
  }
}
