@import '../../styles/variables.scss';

.dsw-footer {
  position: absolute;
  width: 100%;
  height: 50px;
  line-height: 25px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 13px 0px;
  overflow:hidden;
  border: 1px solid $wse_color_blue;
  background-color: $wse_color_blue;

  p {
    color: $wse_color_white;
    font-size: 14px;
    margin-right: 3px;
    margin-bottom: 0px;
    float: left;
    margin-top: 0;
    font-family: $NotoSansRegular;

    &.pointer {
      cursor: pointer;
    }
  }
}
