@import '../../styles/variables.scss';

.wse-logo {
  width: 22px;
  height: 42px;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  height: 65px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border: 1px solid #003359;
  background-color: #003359;
  z-index: 1000;
  text-align: center;
  position: fixed;
  top: initial;
  width: 100%;
}

.nav-menu-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
  align-items: center;
}

.nav-menu-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-menu-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
}

.nav a {
  margin: 0;
  font-size: 16px;
  font-family: $NotoSansBold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #a6b8c5;
  text-decoration: none ;
}

.nav a:hover:not(.active) {
  color: #c7d4dd;
  text-decoration: none;
}

.nav a.active {
  color: white;
  text-decoration: none;
}

.fa-power-off {
  color: #a6b8c5;
}

.dsw-home-icon {
  width: 20px;
  margin-right: 2px;
  vertical-align: middle;
  border-style: none;
}