@import '../../../../styles/variables.scss';

.assetsContainer {
    padding: 32px;
}
.singleImgContainer {
    line-height: 0;
    padding: 32px;
    position:relative;
    cursor: pointer;
    @include hoverImageContainer;
    div:first-of-type {
        position: relative;
        display: inline-block;
        width: auto;
        height: auto;
    }
    img {
        max-height: 480px;
    }
}
.twoColumnImgContainer {
    max-width: 240px;
    max-height: 300px;
    line-height: 0;
    position:relative;
    cursor: pointer;
    @include hoverImageContainer;

    div:first-of-type {
        display: block;

        img {
            max-width: 240px;
            max-height: 300px;
        }
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .twoColumnImgContainer {
        max-width:  100%;
    
        div:first-of-type {
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}