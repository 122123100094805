.gapContainer {
    display: inline-block;
    vertical-align: middle;
    height: 40px;
    margin: 0 5px 5px;
    position: relative;
    &.disabled {
        cursor: not-allowed;
        pointer-events: none;
    }
}
