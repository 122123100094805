@import '../../styles/variables.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 2px;
}
.dropMenu {
  font-size: 14px;
  color: $wse_color_dark_grey_70;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 4em;
  border: 1px solid $wse_color_light_grey_F7;
  cursor: pointer;
  background-color: $wse_color_light_grey_F7;
  padding: 6px 8px;
  margin-left: 4px;
  min-height: 36px;
  b {
      font-family: $NotoSansBold;
      font-weight: 700;
      color: $wse_color_blue;
  }
  img {
      margin-left: 2px;
      width: 16px;
      height: 16px;
      opacity: .6;
  }
}
.list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.listItem {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .45em 2em .45em .8em;
  font-size: 14px;
  color: $wse_color_dark_grey_70;
  min-height: 2.206em;
  border-left: 2px solid transparent;
  word-break: break-word;
}
.lisItemCompleted {
  color: $wse_color_blue;
}
.dropContainer {
    position: absolute;
    left: -0.5em;
    top: 2.25em;
    min-width: 4.5em;
    max-height: 13em;
    overflow: auto;
    border-radius: 4px;
    background-color: $wse_color_white;
    color: $wse_color_dark_grey;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 12%);
}
.currentItem {
  border-left-color: $wse_color_blue;
  background-color: $wse_color_white;
  color: $wse_color_blue;
  font-family: $NotoSansBold;
  font-weight: 700;
  pointer-events: none;
  background-image: linear-gradient(to left, rgba(210, 221, 228, 0), rgba(166, 184, 197, 0.3)),linear-gradient(to bottom, $wse_color_white, $wse_color_white);
}
.completeItem {
  color: $wse_color_blue;
}
.open {
  img {
      transform: rotate(180deg);
  }
}
.completed {
  position: absolute;
  top: .5em;
  right: .8em;
  width: 7px;
  height: 13px;
  border: solid $wse_color_blue;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}