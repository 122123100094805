@import '../../styles/variables.scss';

.dsw-mylibrary {
  .dsw-mylibrary-title {
    padding: 15px;
    font-family: $NotoSansSemiBold;
    margin: 0;
    color: $wse_color_darkest_grey;
    font-size: 28px;
  }
  .dsw-mylibrary-icon {
    width: 60px;
    height: 60px;
    padding: 12px 3px 12px 4px;
  }

  .fa-th-large {
    color: $wse_color_light_grey_AA;
  }

  .fa-list-ul {
    margin-left: 30px;
    color: $wse_color_light_grey_AA;
  }

  .fa-active {
    color: $wse_color_blue;
  }

  .fa {
    cursor: pointer;
    font-size: 25px;
  }

  .position-relative {
    position: relative;
  }
}

