@import '../../../../styles/variables.scss';

.dropBox{
  border: 1px solid $wse_color_light_grey_CC;
  min-height: 248px;
  border-radius: 4px;
}

.wordBank {
  display: inline-flex;
  flex-direction: row;
  overflow: hidden;
  flex-wrap: wrap;
  padding-left: 16px;
  padding-bottom: 16px;
}

.title {
    margin: 10px;
    font-family: $NotoSansSemiBold;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: $wse_color_darkest_grey;
    margin: 16px 0;
}

.dashedBorder {
    border-style: dashed;
    border-color: $wse_color_light_blue;
 }
