@import '../../../../styles/variables.scss';

.emailContainer {
    padding: 1em;
}
.dotContainer {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    li {
        background-color: rgba(255, 142, 126, 0.65);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        &:not(:last-child) {
            margin-right: 8px;
        }
    }
}
.indentContent {
    padding: 2.5em 1.5em 1.5em;
    p {
        img {
            min-width: 50px;
            min-height: 50px;
            max-width: 240px;
            max-height: 300px;
            margin: 10px 0;
        }
    }
}
