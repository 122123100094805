@import "../../../styles/variables.scss";

.dsw-footer-policies-modal {
  .dsw-modal-body {
    max-height: calc(90vh - (72px + 125px));
    overflow-y: scroll;
    overflow-x: hidden;
    overflow-wrap: break-word;
    padding-top: 0px;

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    .wse-typo-body {
      font-family: $NotoSansRegular;
      text-transform: none;
      font-size: 1em;
      line-height: 1.5em;
      color: $wse_color_darkest_grey;

      &.policy-updated-date {
        margin-bottom: 10px;
      }

      &.summary-title {
        text-align: center;
      }
    }

    .dsw-typo-default-font-size {
      font-size: 16px;
    }

    // Font family for Burmese
    .dsw-language-font-my {
      .wse-typo-body {
        font-family: "Pyidaungsu";
      }
    }

    // Font family for Vietnamese
    .dsw-language-font-vi {
      .wse-typo-body {
        font-family: "Noto Sans";
      }
    }

    .decrease-content-font {
      .wse-typo-body {
        font-size: 14px;
      }
    }

    .alpha-list {
      list-style-type: lower-alpha;
    }

    .privacy-policy-summary-table {
      margin: 10px 0px;
      font-size: 14px;
      border-collapse: collapse;
      border-spacing: 0;

      tr {
        border: 1px solid $wse_color_grey_80;

        & > td:first-child {
          border-right: 1px solid $wse_color_grey_80;
        }

        td {
          padding: 8px;
          line-height: 24px;
        }
      }
    }
  }
}
