@import '../../../../styles/variables.scss';

.container {
    background-color: rgba(255, 216, 210, 0.2);
    position: relative;
}
.spacingContainer {
    padding: 27px 12px 12px 27px;
    height: calc(100% - 64px);
}
.scrollContainer {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    @include hoverScrollbars;
    
}
.customContainer {
    margin: 5px;
    box-shadow: 0 0 4px 0 $wse_color_peach_35;
    background-color: $wse_color_white;
}
.footer {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    text-align: right;
    padding: 0 32px 16px 0;
    min-height: 64px;
    @media (min-width: 768px) and (max-width: 1023px) {
        position: relative;
        padding: 0px 4px 0px 27px;
    }
}