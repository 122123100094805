@import '../../../styles/variables.scss';

.essay {
  .answered {
    width: 100%;
    border-radius: 4px;
    border: solid 1px $wse_color_light_grey_CC;
    background-color: $wse_color_white;
    padding: 5px 15px;
    font-size: 1.125em;
    color: $wse_color_darkest_grey;
    line-height: 1.56;
    margin-bottom: 16px;
    min-height: 40px;
    word-break: break-word;

    span {
      font-family: inherit;
      margin: 0;
      white-space: pre-wrap;
    }
  }

  > span {
    flex-direction: column;
    p {
      margin: 0;
      font-size: 1.125em;
    }
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .essay {
    .answered { 
        font-size: 1em;
        line-height: 1.5;
    }
    > span {
      p {
        font-size: 1em;
      }
    }
  } 
}