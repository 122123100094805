@import '../../styles/variables.scss';

.dsw-accordion-item {
  background-color: $wse_color_white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 0;
  border-radius: 4px;
  border-top: 1px solid $wse_color_light_grey_F5;
  border-bottom: 1px solid $wse_color_light_grey_F5;

  .dsw-accordion-heading {
    height: 46px;
    z-index: 2;
    border-radius: 0px;
    width: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    color: $wse_color_dark_grey_64;
    background: $wse_color_light_grey_FA;
    border-color: $wse_color_light_grey_DD;
    cursor: pointer;
    text-transform: uppercase;

    .dsw-accordion-title {
      font-family: $NotoSansRegular;
      font-size: 18px;
      line-height: 1.5;
      margin-top: 0;
      margin-bottom: 0;
      display: block;
      width: 100%;
      padding: 10px;

      .dsw-accordion-arrow {
        margin-left: .3em;
        margin-top: .3em;
        margin-right: .3em;
        float: right
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .dsw-accordion-content {
    border: none;
    padding: 15px;
  }
}