@import '../../styles/variables.scss';

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  z-index: 10;
  pointer-events: none;

  .loader {
    font-size: 10px;
    border-top: 1.1em solid $wse_color_blue_35;
    border-right: 1.1em solid $wse_color_blue_35;
    border-bottom: 1.1em solid $wse_color_blue_35;
    border-left: 1.1em solid $wse_color_blue;
  }

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10.3em;
    height: 10.3em;

    @media only screen and (max-width: 992px) {
      width: 8em;
      height: 8em;
    }

    @media only screen and (max-width: 768px) {
      width: 5em;
      height: 5em;
    }
  }

  &.loaderAnimation {
    transform: translateX(0) translateY(0);

    .loader {
      transform: translateZ(0);
      animation: loading 1.1s infinite linear;
    }
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
