@import '../../styles/variables.scss';

.matching {
  grid-column-gap: 0px !important;
  grid-row-gap: 16px !important;
  padding-top: 18px;
  padding-bottom: 20px;

  .mdc-layout-grid__cell--span-5.first-segment {
    grid-column-start: 2;
  }

  .first-segment {
    grid-auto-rows: max-content;

    .item {
      padding-right: 0px;
      margin-right: 32px;

      &.hovering {
        background-color: $wse_color_white;
        border: 1px solid $wse_color_cyan_75 !important;
      }

      &.correct {
        position: relative;
        overflow: hidden;
        border-right: 1px solid $wse_color_white !important;
      }

      &.wrong {
        position: relative;
        overflow: hidden;
        border: 1px solid $wse_color_red;
      }

      &.example {
        border-right: 1px solid $wse_color_white;
      }

      &.solution {
        border-right: 0;
      }

      &.matched,
      &.example,
      &.solution {
        margin-right: 16px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.matched:not(.hovering) {
        border-right: 0;
      }
    }
  }

  .second-segment {
    grid-auto-rows: max-content;

    .item {
      &.matched,
      &.example,
      &.solution {
        margin-left: -16px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &.solution {
        border-left: 0;
      }

      &.matched.hovering {
        border-left: 0 !important;
      }

      &.selected {
        border: 1px dashed $wse_color_light_blue !important;
        background-color: $wse_color_white;
      }

      &.wrong {
        border: 1px solid $wse_color_red;
      }
    }
  }

  .item {
    position: static;
    top: 0px;
    cursor: pointer;
    border-radius: 4px;
    background-color: $wse_color_cyan_35;
    display: flex;
    margin-bottom: 16px;

    &.selected {
      background-color: $wse_color_cyan;
    }

    &.solution {
      border: solid 2px $wse_color_cyan_75;
      background-color: $wse_color_light_blue_25;
      pointer-events: none;
    }

    &.example {
      background-color: rgba(166, 211, 225, 0.3);
      pointer-events: none;
    }

    &.matched {
      border: solid 1px $wse_color_cyan_75;
      background-color: $wse_color_white;
    }

    &.wrong {
      background-color: $wse_color_red_25;
      border: none;
    }

    &.correct {
      background-color: $wse_color_green_20;
      border: none;
      pointer-events: none;
    }

    &.completed {
      pointer-events: none;
    }

    &.completed:not(.matched):not(.example):not(.solution) {
      background-color: $wse_color_light_grey_F7;
    }

    p {
      margin: 0;
      align-self:center;
      padding: 12px 16px;
      color: $wse_color_darkest_grey;
      font-size: 18px;
      line-height: 1.34;
    }
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    .item {
      p {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }

  .item:last-child { 
    margin-bottom: 0px;
  }
}

