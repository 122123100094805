@import '../../styles/variables.scss';

.outer-ring {
  position: relative;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;

  .inner-ring {
    height: 10px;
    width: 10px;
    background-color: $wse_color_light_grey_CC;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 2px;
    top: 2px;

    &.display {
      background-color: $wse_color_blue;
    }
  }

  &.display {
    border: 1px solid $wse_color_blue;
  }
}
