@import '../../../styles/variables.scss';

.categoryContainer {
    overflow: hidden;
    &:not(:last-child) {
        box-shadow: inset 0 -6px 6px -6px $wse_color_light_grey_CC;
    }
    .categoryContent {
        margin: 0 12px 0 20px;
        height: calc(100% - 60px);
        overflow-x: hidden;
        overflow-y: auto;
        @include hoverScrollbars;
        p {
            font-size: 1em;
            margin: 0 4px 13px 4px;
            line-height: 1.5;
            i {
                font-family: $NotoSansSemiBoldItalic;
                font-weight:600;
            }
        }
    }
}

.expendedCategoryContainer {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1002;
    background-color: $wse_color_white;
    padding: 16px 12px 16px 16px;
    transition: all 0.4s ease-out;
    .expendedCategoryContent {
        height: calc(100% - 30px);
        overflow-x: hidden;
        overflow-y: auto;
        @include hoverScrollbars;
    }
    p {
        font-size: 1em;
        margin: 0 0 .7em 0;
        i {
            font-family: $NotoSansSemiBoldItalic;
            font-weight:600;
        }
    }
}
