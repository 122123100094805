@import '../../../../styles/variables.scss';

.fullOccupyContainer {
    width: 100%;
    height: 100%;
    display: inline-block;
}

.emptyBordered {
    border: 1px dashed $wse_color_light_blue;
    background-color: $wse_color_white;
    position: absolute;
    border-radius: 4px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
    &.show {
        opacity: 1;
    }
}