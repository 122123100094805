@import '../../styles/variables.scss';

.inputFieldContainer {
    position: relative;
    display: inline-flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    &.fullContainer {
        margin-left: 0;
        margin-right: 0;
    }
}

.hidden {
    position: absolute;
    left: 0;
    opacity: 0;
    z-index: -100;
    white-space: pre;
    padding: 6px 16px 5px;
}

.inputField {
    border: none;
    border-bottom: 2px dotted $wse_color_dark_grey_70;
    padding: 4px 16px;
    min-width: 170px;
    max-width: 300px;
    outline: none;
    min-height: 40px;
    line-height: 1.33;
}

.focused {
    border-bottom: 2px solid $wse_color_blue;
}

.filled {
    border-bottom: 2px solid $wse_color_dark_grey_70;
}

.wrongAnswer {
    border-bottom: 2px solid $wse_color_red;
}

.fullContainer {
    width: 100%;
    max-width: 100%;
}