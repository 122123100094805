@import '../../../styles/variables.scss';

.eraseContainer {
  display: flex;
  background: $wse_color_white;
  display: flex;
  flex-direction: column;
  min-width: 419px;
  justify-content: center;
  border-radius: 6px;
  padding: 10px 10px 32px 64px;

  .text {
    font-family: $NotoSansRegular;
    font-size: 18px;
    color: $wse_color_darkest_grey;
    margin-top: 28px;
  }

  .closeButton {
    align-self: flex-end;
    padding: 7px 6px 6px 7px;

    .closeIcon {
      cursor: pointer;
    }
  }

  .buttonContainer {
    align-self: flex-end;
    padding-top: 46px;
    padding-right: 14px;
  }
}


