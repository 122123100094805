@import '../../../styles/variables.scss';

.tooltip .tooltip-content span {
    font-size: 16px;
    line-height: 1.5;
    color: $wse_color_darkest_grey;
    margin-top: 16px;
}

.tooltip .tooltip-content span:nth-child(1) {
    margin-top: 0;
}

.tooltip .tooltip-content .user-answer {
  color: $wse_color_blue;
  font-family: $NotoSansSemiBold;
  font-weight:600;
}