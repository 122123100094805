@import '../../../../styles/variables.scss';

.expendedCategory {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1002;
    background-color: $wse_color_white;
    padding: 1em .75em 1em 1em;
    transition: all 0.4s ease-out;
    .categoryContent {
        line-height: 1.5;
        height: calc(100% - 30px);
        overflow-x: hidden;
        overflow-y: auto;
        @include hoverScrollbars;
    }
    p {
        font-size: 1em;
        margin: 0 0 .7em 0;
        i {
            font-family: $NotoSansSemiBoldItalic;
            font-weight:600;
        }
    }
}
