@import '../../styles/variables.scss';

.chip {
    display: inline-block;
    padding: 3px 15px;
    font-size: 1em;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border: 1px solid $wse_color_dark_grey_70;
    background-color: $wse_color_white;
    border-radius: 16px;
    margin: 6px 4px;
    cursor: pointer;
    &:hover {
        border: 1px solid $wse_color_cyan_75;
    }
    &:active {
        background-color: $wse_color_cyan_75;
        border-color: $wse_color_cyan_75;
        color: $wse_color_darkest_grey;
    }
}